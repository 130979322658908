<template>
	<div>
		<page-title-component
            title="Usuários"
            subtitle="Usuários"
        />

        <div class="ml-5 mt-4" >
        	Carregados ({{ users.length }})
        </div>

        <div v-if="!loading" >
			<div
				v-if="users.length > 0"
				class="px-4 mt-3"
			>
				<div v-if="users.length < 10" >
					<list-users-component :users="users" ></list-users-component>
				</div>
				<vuescroll v-else @handle-scroll="handleScroll($event)" >
					<list-users-component :users="users" ></list-users-component>
				</vuescroll>
				<br>
			</div>
			<div
				v-if="!loading && users.length === 0" class="d-flex justify-content-center align-items-center"
				style="height: 300px" >
				<span>Nenhum usuário submeteu o contacto</span>
			</div>
		</div>
		<div
			v-else class="d-flex justify-content-center align-items-center"
			style="height: 300px" >
			<span>Carregando usuários ...</span>
		</div>
	</div>
</template>

<script>
    import PageTitleComponent from "../components/base/PageTitleComponent"
    import ListUsersComponent from '../components/ListUsersComponent'
    import vuescroll from 'vuescroll'

	import { mapState, mapActions, mapMutations } from 'vuex'

	export default {
		name: "UsersPage",
		components: {
			PageTitleComponent,
			ListUsersComponent,
			vuescroll
		},

		data () {
			return {
				loading: false
			}
		},

		computed: {
			...mapState([
				'users',
				'loadingMore'
			])
		},

		methods: {
			...mapActions([
				'getUsers',
				'getMoreData'
			]),

			...mapMutations(['setLoadingMore']),

			requestUsers () {
				this.getUsers().then(response => {
					this.loading = false
				})
				.catch(error => {
					this.loading = false
				})
			},

			handleScroll (vertical) {

				if (vertical.process >= 0.95) {

					let lastUser = this.users[this.users.length - 1]

					if (!this.loadingMore) {
						this.setLoadingMore(true)

						this.getMoreData(lastUser.id)
					} else {
						console.error('wait, data already loading more')
					}
				}
			},

			handleGetUsers (verification = true) {

				if (verification) {
					if (this.users.length === 0) {
						this.loading = true

						this.requestUsers()
					}

					return
				}

				this.requestUsers()
				return
			},

			initialize () {
				this.handleGetUsers()
			}
		},

		mounted () {
			this.initialize()
		}
	}
</script>