import { render, staticRenderFns } from "./SingleBlogComponent.vue?vue&type=template&id=d258d560&scoped=true&"
import script from "./SingleBlogComponent.vue?vue&type=script&lang=js&"
export * from "./SingleBlogComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d258d560",
  null
  
)

export default component.exports