<template>
    <!-- About Us Area Start Here -->
    <section class="about-wrap-layout4">
        <div class="container">
            <div class="row">
                <div class="col-xl-7 col-12">
                    <div class="about-box-layout5">
                        <div class="item-img">
                            <img src="img/about/about4.png" alt="about">
                        </div>
                    </div>
                </div>
                <div class="col-xl-5 col-12">
                    <div class="about-box-layout6">
                        <div class="item-content">
                            <h2 class="item-title">NORB CONSULTING</h2>
                            <p>
                                A Norb Consulting é uma empresa de Recursos Humanos especializada na busca e identificação de talentos em Moçambique.
                                Nossa experiência e realização se concentram na: Seleção, Gestão e desenvolvimento de pessoas, Programas de Assessment,
                                Seleção por Competência, Treinamento e desenvolvimento, Qualidade e gestão empresarial em empresas.
                            </p>
                            <p>
                                Acreditamos que o nosso know-how é o que pode marcar a diferença em termos de
                                contributo para a sociedade com umo um todo.
                                Como primeira empresa de consultoria a certificar-se a nível ibérico na Norma ISO 9001,
                                oferece soluções integrais no âmbito do Talento, sendo especialista em recrutamento e seleção,
                                avaliação e desenvolvimento, formação, consultoria e outsourcing, aplicando inovação digital nos seus processos.
                            </p>

                            <!--<a href="#" class="ghost-btn-2 text-accent border-accent">FREE CONSULTATION<i class="fas fa-arrow-circle-right"></i></a>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Us Area End Here -->
</template>

<script>
    export default {
        name: "AboutSectionOneComponent"
    }
</script>

<style scoped>

</style>