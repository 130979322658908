<template>
    <div>

        <page-title-component
            title="Serviços"
            subtitle="Um serviço apenas"
        />

        <div class="row text-center">
            <div class="col-md-8 col-xs-12 px-5 offset-md-2" >
                <h2 class="item-title">
                    Nossos Serviços
                </h2>
                <div class="flex flex-center px-5" >
                    <section class="text-left" >
                        <p>
                            A Norbé uma empresade Recursos Humanos especializada na busca e identificação de talentos, em Moçambique.
                        </p>

                        <p>
                            Nossa experiência e realização se concentram na Seleção, Gestão e Desenvolvimento de Pessoas, através de programas de Assesment, Seleção por Competência, treinamento e desenvolvimento, qualidade e gestão empresarial em empresas de diferentes segmentos.
                        </p>

                        <p>
                            Acreditamos que o nosso know-how é o que pode marcar a diferença em termos de contributo para a sociedade com um todo.
                        </p>

                        <p>
                            Como primeira empresa de consultoria a certificar-se a nível ibérico na Norma ISO 9001, oferece soluções integrais no âmbito do Talento, sendo especialista em recrutamento e seleção, avaliação e desenvolvimento, formação, consultoria e Outsourcing, aplicando inovação digital nos seus processos.
                        </p>
                    </section>
                </div>
            </div>
        </div>

        <services-list-component />

        <div class="row text-center">
            <div class="col-md-8 col-xs-12 px-5 offset-md-2" >
                <div class="flex flex-center px-5" >
                    <section class="text-left" >
                        <p>
                            Nosso o objetivo é encantar aos participantes da nossa atmosfera, com a identificação dos talentos conforme perfil delineado por nossos clientes e pelo desenvolvimento de projetos de gestão em RH.
                        </p>

                        <p>
                            Nossa experiência revela os valores que cultivamos junto aos clientes, candidatos e parceiros, sempre pautados na ética e no resultado, entendemos que o alinhamento estratégico é nosso sucesso. 
                        </p>

                        <p>
                            Com foco em relações duradoras e bem-sucedidas a longo prazo, por meio de tratamento diferenciado, fazemos com que cada um seja único quando envolvidos em nosso cenário.
                        </p>

                        <p>
                            Seja bem vindo ao Mundo da Norb e desfrute do que há de melhor em termos de conteúdo e prática quando lidamos com pessoas!
                        </p>
                    </section>
                </div>
            </div>
        </div>

    </div>
</template>

<script>


    import PageTitleComponent from "../components/base/PageTitleComponent";
    import BrandComponent from "../components/BrandComponent";
    import ServicesListComponent from "../components/services/ServicesListComponent";

    export default {
        name: "ServicesPage",
        components: {
            ServicesListComponent,
            BrandComponent,
            PageTitleComponent
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        }
    }
</script>

<style scoped>

</style>