<template>
    <div>

        <page-title-component
            title="Contactos"
            subtitle="Contacte-nos"
        />

        <contact-section-component />

    </div>
</template>

<script>

    import PageTitleComponent from "../components/base/PageTitleComponent";
    import ContactSectionComponent from "../components/contact/ContactSectionComponent";

    export default {
        name: "ContactPage",
        components: {
            ContactSectionComponent,
            PageTitleComponent
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        }
    }
</script>

<style scoped>

</style>