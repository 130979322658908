<template>
    <div>

        <page-title-component
                title="Quatro formas de receber um novo colaborador"
                subtitle="Um Blog apenas"
                :secondSection="{
                title: 'Blog',
                link: '/blog',
            }"
        />

        <!-- Blog Area Start Here -->
        <section class="single-blog-wrap-layout1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <div class="single-blog-box-layout1">
                            <div class="post-img">
                                <img :src="blogPost.img" style="width: 100%" alt="Blog">
                            </div>
                            <div class="item-content">
                                <div class="post-tag gradient-accent">Artigo</div>
                                <h2 class="item-title">
                                    {{ blogPost.title }}
                                </h2>
                                <div class="entry-content">
                                    <ul class="entry-meta">
                                        <li><i class="far fa-user-circle"></i>NORB</li>
                                        <li><i class="far fa-clock"></i>25 Outubro de 2020</li>
                                    </ul>

                                </div>
                                <p class="mt-5">
                                    Um excelente CV ainda é um dos maiores factores para conseguir um bom trabalho. Afinal de contas, apresenta aos recrutadores e potenciais empregadores uma visão clara e rápida das suas capacidades, experiência e formação relevantes para que decidam convidá-lo para uma entrevista ou não. E se bem-sucedido, ajuda a balancear a primeira de muitas decisões ao procurar trabalho.
                                </p>

                                <p class="mt-5">
                                    No entanto, não importa quão boas são as suas referências, se não tiver um bom CV para as acompanhar, provavelmente a sua candidatura será rejeitada ao candidatar-se a uma nova função. Considere isto: de acordo com um estudo do The Ladders, os recrutadores passam apenas seis segundos a ver um CV. Isso significa que tem apenas uma pequena janela de oportunidade para causar uma
                                    boa impressão! Por outras palavras, necessita de um CV que se destaque em todos os sentidos. Iremos ajuda-lo a preparar um CV que demonstra quais os factores a dar importância.
                                </p>

                                <ul class="list-item">
                                    <li><i class="fas fa-check-circle"></i>

                                        <b> Restrinja o seu CV. </b> Os recrutadores vão procurar no seu CV informação relevante, assim sendo, apresente os seus dados de forma simplificada, em vez de utilizar parágrafos longos. Utilize palavras-chave relevantes para a profissão e experiência completa. Por exemplo, se for gestor de projecto, use palavras-chave como “gestão de projectos,” “supervisão,” e “liderança.”

                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>


                                        <b> O seu CV não deve exceder duas páginas. </b> Poucos recrutadores têm tempo para ver CVs muito longos. Assim sendo, mesmo que tenha mais experiência do que a que cabe em duas páginas, deve utilizar a informação mais relevante. Remova palavras e considere omitir um ou dois dos seus trabalhos mais recentes ou condense as descrições das suas responsabilidades em cada uma das posições.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Não se afaste muito do formato convencional de CV. </b> Quase todas as candidaturas actualmente passam por sistemas de registo e avaliação de candidaturas. Isso significa que o seu CV será primeiramente verificado por um computador antes de ser visto por uma pessoa. Os sistemas de controlo de candidatos são projectados para determinar certos dados, incluindo dados pessoais, objectivo de carreira, capacidades, experiência e formação, assim sendo, certifique-se que enumera claramente todos estes pontos. Informação
                                        adicional como Associações Profissionais, Prémios e Publicações é opcional. Inclua-as unicamente se sentir que ajudará na candidatura.
                                    </li>


                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Forneça uma visão concisa do seu objectivo de carreira. </b> No artigo do USA Today “5 regras básicas para um bom CV,” Patrick O' Brien recomenda descrever o que quer realizar profissionalmente de forma a ilustrar o que pode
                                        fazer pelo empregador. Por exemplo, se for um gestor que pretende ganhar experiência internacional, poderia indicar algo como: “gestor altamente capaz com capacidades de liderança excepcionais e uma compreensão global do sector.”
                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Não inclua demasiada informação pessoal. </b> No artigo da CBS “Como redigir um CV": regras básicas”, Suzanne Lucas alerta para o perigo de incluir informação tal como religião, data de nascimento, estado civil, passatempos ou ligações a páginas de redes sociais.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Liste resultados quantificáveis. </b> Potenciais empregadores querem ver realizações profissionais no seu CV e a maneira mais simples de as comunicar
                                        é usando números, sempre que possível.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Liste a sua experiência em ordem cronológica inversa, </b> começando pelo seu
                                        último emprego. Liste primeiro o último trabalho e vá andando para trás. Se não tiver espaço suficiente para listar todos os trabalhos que realizou, liste apenas os últimos 10 anos e prepare-se para falar sobre trabalhos mais antigos numa entrevista pessoal.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b>Não exagere nas funções, responsabilidades ou resultados obtidos. </b> Mencione toda a informação de forma correta, sem exageros ou artifícios. Lembre-se: a maioria dos empregadores verifica referências e vão perguntar sobre o seu desempenho em trabalhos anteriores.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Certifique-se que tem versões electrónicas e em papel do seu CV. </b> É uma boa ideia ter o seu CV em vários formatos diferentes, incluindo PDF imprimível, PDF interactivo, Word e em texto, no caso de necessitar de inserir mais informação.

                                    </li>

                                </ul>

                                <p class="mt-5">
                                    <b>Não se esqueça de actualizar regularmente o seu CV.</b> Mesmo que não esteja à procura de trabalho de forma activa, nunca se sabe quando alguém pode pedir o seu CV, assim sendo, certifique-se que o mantém actualizado. Dessa forma, quando ouvir falar de um trabalho interessante num evento de networking, estará pronto para se candidatar imediatamente.
                                </p>
                                <p class="mt-5">
                                    Redigir um CV eficaz leva tempo e necessita de preparação. Mas com estas regras básicas em mente, aumentará as possibilidades de se destacar de outros candidatos, conseguindo obter uma entrevista para um novo trabalho.
                                </p>

                            </div>
                            <div class="item-tag">
                                <ul>
                                    <li><i class="fas fa-tags"></i><a href="#">Blog,</a></li>
                                    <li><a href="#">Dicas</a></li>
                                    <!--<li><a href="#">Optimization</a></li>-->
                                </ul>
                            </div>
                            <div class="blog-author">
                                <div class="media media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog25.jpg" class="media-img-auto" alt="Lora Zaman">
                                    </div>
                                    <div class="media-body media-none-sm">
                                        <h4 class="item-title">{{ blogAuthor.name }}</h4>
                                        <div class="item-subtitle">Author</div>
                                        <p>{{ blogAuthor.description }}</p>
                                        <ul class="item-social">
                                            <li><a href="#" class="bg-fb"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#" class="bg-twitter"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#" class="bg-linked"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#" class="bg-gplus"><i class="fab fa-google-plus-g"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="pagination-layout3">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="prev-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure8.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#"><i class="fas fa-chevron-left"></i>Previous Post</a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="next-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure9.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body mg-right text-right">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#">Next Post<i class="fas fa-chevron-right"></i></a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->

                        </div>
                    </div>

                    <div class="col-lg-4" >
                        <blog-side-nav-component class="col-lg-12" />
                    </div>

                </div>
            </div>
        </section>
        <!-- Blog Area End Here -->

    </div>
</template>

<script>

    import { mapState } from 'vuex'

    import PageTitleComponent from "../../components/base/PageTitleComponent";
    import SingleBlogComponent from "../../components/blog/SingleBlogComponent";
    import BlogSideNavComponent from "../../components/blog/BlogSideNavComponent";
    export default {
        name: "BlogDicasParaElaborarCVPage",
        components: {
            BlogSideNavComponent,
            SingleBlogComponent,
            PageTitleComponent
        },
        computed: {
            ...mapState([
                'blogs',
                'authors'
            ]),
            blogLink () {
                return this.$route.fullPath
            },
            blogPost () {
                return this.blogs.find(b => b.link === this.blogLink)
            },
            blogAuthor () {
                return this.authors.find(author => author.id === this.blogPost.author_id)
            }
        },
        filters: {
            limitLength (str, max) {
                return str.length > max ? str.substr(0, max) + '...' : str
            }
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        },

    }
</script>

<style scoped>

</style>