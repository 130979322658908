<template>
    <!-- Blog Area Start Here -->
    <section class="blog-wrap-layout6" >
        <div class="container" >
            <div class="row" >

                <div class="col-lg-8 vertical-aligned-blogs" >

                    <div class="row px-5 my-4" >
                        <button
                            @click="setActiveBlogs(page)"
                            v-for="(page, i) in paginatedBlogs.length"
                            :key="i"
                            class="btn btn-default ml-2"
                            :class="activeBtn(page) ? 'btn-primary' : ''"
                            style="font-size: 1.1em"
                        >{{ page }}</button>
                    </div>

                    <blog-component
                        v-for="(blog, i) in activeBlogs"
                        :key="'dynamic_blog_' + i"
                        :blog="blog"
                    />

                    <div class="row px-5 my-4" >
                        <button
                            @click="setActiveBlogs(page)"
                            v-for="(page, i) in paginatedBlogs.length"
                            :key="i"
                            class="btn btn-default ml-2"
                            :class="activeBtn(page) ? 'btn-primary' : ''"
                            style="font-size: 1.1em"
                        >{{ page }}</button>
                    </div>
                </div>

                <div
                    class="col-xl-4 col-lg-4 mt-4"
                >
                    <blog-side-nav-component class="col-lg-12" />
                </div>

            </div>

        </div>
    </section>
    <!-- Blog Area End Here -->
</template>

<script>
    import BlogComponent from "./BlogComponent"
    import BlogSideNavComponent from "./BlogSideNavComponent"

    import { mapState, mapMutations } from 'vuex'

    export default {
        name: "BaseBlogComponent",
        components: {
            BlogComponent,
            BlogSideNavComponent
        },

        data () {
            return {
                activeBlogs: []
            }
        },

        computed: {
            ...mapState([
                'blogs',
                'activePage'
            ]),

            paginatedBlogs () {
                let paginated = []
                let list = []

                let perPage = 5

                for (let i = 0; i < this.blogs.length; i++) {
                    list.push(this.blogs[i])

                    let isAtLimit = i === (this.blogs.length - 1)

                    if (i === (perPage - 1) || isAtLimit) {
                        let page = {}

                        page.page = list

                        paginated.push(page)

                        perPage = perPage + 5
                        list = []
                    }
                }

                return paginated
            }
        },

        methods: {
            ...mapMutations(['setActivePage']),

            setActiveBlogs (page) {
                this.activeBlogs = this.paginatedBlogs[page - 1].page

                this.setActivePage(page)
            },

            activeBtn (page) {
                return page === this.activePage
            }
        },

        mounted () {
            this.setActiveBlogs(this.activePage)
        }
    }
</script>