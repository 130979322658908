<template>
    <!-- Inne Page Banner Area Start Here -->
    <section class="inner-page-banner bg-common" data-bg-image="img/figure/breadcumb.jpg">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="breadcrumbs-area">
                        <ul>
                            <li>
                                <router-link to="/">Início</router-link>
                            </li>
                            <li v-if="secondSection">
                                <router-link :to="secondSection.link">{{ secondSection.title }}</router-link>
                            </li>
                            <li>{{ title }}</li>
                        </ul>
                        <h1>{{ subtitle }}</h1>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Inne Page Banner Area End Here -->
</template>

<script>
    export default {
        name: "PageTitleComponent",
        props: ['title', 'subtitle', 'secondSection']

    }
</script>

<style scoped>

</style>