<template>
	<div>
		<page-title-component
            title="Usuário"
            subtitle="Usuário"
        />

        <div v-if="!loading" >
	        <div
	        	v-if="userFound"
	        	class="contact-form-box-layout1 px-5" >
		        <form id="contact-form" class="contact-form-box" >
		            <div class="row gutters-15">
		                <div class="col-lg-6 col-12 form-group" >
		                    <label>Nome *</label>
		                    <input
		                    	disabled :value="userFound.name"
		                        type="text" placeholder="" class="form-control"
		                        name="name" data-error="Name field is required" required>
		                    <div class="help-block with-errors"></div>
		                </div>
		                <div class="col-lg-6 col-12 form-group" >
		                    <label>Email *</label>
		                    <input
		                    	disabled :value="userFound.email"
		                        type="email" placeholder="" class="form-control"
		                        name="email" data-error="email field is required" required>
		                    <div class="help-block with-errors"></div>
		                </div>
		                <div class="col-md-6 col-12 form-group" >
		                    <label>Telemóvel *</label>
		                    <input
		                    	disabled :value="userFound.telephone"
		                        type="text" placeholder="" class="form-control"
		                        name="phone" data-error="Phone field is required" required>
		                    <div class="help-block with-errors"></div>
		                </div>
		                <div class="col-md-6 col-12 form-group" >
		                    <label>Assunto *</label>
		                    <input
		                    	disabled :value="userFound.subject"
		                        type="text" placeholder="" class="form-control"
		                        name="subject" data-error="Subject field is required" required>
		                    <div class="help-block with-errors"></div>
		                </div>
		                <div class="col-12 form-group" >
		                    <label>Mensagem *</label>
		                    <textarea
		                    	disabled :value="userFound.message"
		                        placeholder="" class="textarea form-control"
		                        name="message" id="form-message" rows="5" cols="20"
		                        data-error="Message field is required" required></textarea>
		                    <div class="help-block with-errors"></div>
		                </div>
		            </div>
		            <div class="form-response" ></div>
		        </form>
		    </div>
		    <div
				v-else class="d-flex justify-content-center align-items-center"
				style="height: 300px" >
				<span>Usuário não encontrado</span>
			</div>
		</div>
	    <div
			v-else class="d-flex justify-content-center align-items-center"
			style="height: 300px" >
			<span>Carregando usuário ...</span>
		</div>
	</div>
</template>

<script>
	import PageTitleComponent from "../components/base/PageTitleComponent"

	import { mapState, mapActions } from 'vuex'

	export default {
		name: "UserDetailsPage",
		components: {
			PageTitleComponent
		},

		data () {
			return {
				userFound: null,
				loading: false
			}
		},

		computed: {
			...mapState(['users'])
		},

		methods: {
			...mapActions(['getUserByIdFromFirestore']),

			findUser () {
				let userId = this.$route.params.id

				let user = this.users.find(user => user.id === userId)

				if (user) {
					this.userFound = user
				} else {
					this.loading = true

					this.getUserByIdFromFirestore(userId).then(user => {
						if (user) {
							this.userFound = user
						}

						this.loading = false
					})
				}
			},

			initialize () {
				this.findUser()
			}
		},

		mounted () {
			this.initialize()
		}
	}
</script>