<template>
    <!-- Contact Page Area Start Here -->
    <section class="contact-page-wrap-layout1" >
        <div class="container" >
            <div class="contact-page-box-layout1" >
                <div class="google-map-area" >

                    <div class="col-xs-12">
                        <div class="map">
                            <iframe
                                width="100%" height="450" src="https://maps.google.com/maps?q=Mo%C3%A7ambique,%20Maputo,%20Polana%20Cimento%20A,%20Jos%C3%A9%20Mateus,%20N185&t=&z=19&ie=UTF8&iwloc=&output=embed" frameborder="0"
                                scrolling="no" marginheight="0" marginwidth="0" allowfullscreen="allowfullscreen" class="page_speed_114131883"
                            ></iframe>
                        </div>
                    </div>
                </div>
                <div class="row" >
                    <div class="col-lg-6" >
                        <div class="contact-list" >
                            <div class="heading-layout5 mg-b-30">
                                <h2>Informação</h2>
                            </div>
                            <div class="media">
                                <div class="item-icon">
                                    <i class="flaticon-call-answer"></i>
                                </div>
                                <div class="media-body">
                                    <h5 class="item-title">Phone</h5>
                                    <ul>
                                        <!--<li>Phone: 888-123-4567</li>-->
                                        <li>(+258) 84 87 62 037</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="media">
                                <div class="item-icon">
                                    <i class="flaticon-maps-and-flags"></i>
                                </div>
                                <div class="media-body">
                                    <h5 class="item-title">Endereço</h5>
                                    <ul>
                                        <li>José Mateus, Nr. 185, Polana Cimento A</li>
                                        <li>Maputo, Mozambique</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="media">
                                <div class="item-icon">
                                    <i class="flaticon-mail"></i>
                                </div>
                                <div class="media-body">
                                    <h5 class="item-title">Email</h5>
                                    <ul>
                                        <li>comercial@norb.co.mz</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6" >
                        <div class="contact-form-box-layout1" >
                            <div class="heading-layout5 mg-b-30" >
                                <h2>Contacte-nos a qualquer momento</h2>
                            </div>
                            <form
                                @submit.prevent="handleSaveData()"
                                class="contact-form-box" id="contact-form" >
                                <div class="row gutters-15">
                                    <div class="col-lg-6 col-12 form-group" >
                                        <label>Nome *</label>
                                        <input
                                            v-model="formData.name" type="text" placeholder="" class="form-control"
                                            name="name" data-error="Name field is required" required>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <div class="col-lg-6 col-12 form-group" >
                                        <label>Email *</label>
                                        <input
                                            v-model="formData.email" type="email" placeholder="" class="form-control"
                                            name="email" data-error="email field is required" required>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <div class="col-md-6 col-12 form-group" >
                                        <label>Telemóvel *</label>
                                        <input
                                            v-model="formData.telephone" type="text" placeholder="" class="form-control"
                                            name="phone" data-error="Phone field is required" required>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <div class="col-md-6 col-12 form-group" >
                                        <label>Assunto *</label>
                                        <input
                                            v-model="formData.subject" type="text" placeholder="" class="form-control"
                                            name="subject" data-error="Subject field is required" required>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <div class="col-12 form-group" >
                                        <label>Mensagem *</label>
                                        <textarea
                                            v-model="formData.message" placeholder="" class="textarea form-control"
                                            name="message" id="form-message" rows="5" cols="20"
                                            data-error="Message field is required" required></textarea>
                                        <div class="help-block with-errors"></div>
                                    </div>
                                    <div class="col-12 form-group" >
                                        <button
                                            v-if="!loading && !sent" type="submit"
                                            class="btn-fill-3 gradient-accent" >Submeter agora<i class="fas fa-chevron-right" ></i>
                                        </button>
                                        <button
                                            v-if="loading"
                                            class="btn-fill-3 gradient-accent"
                                            disabled >Enviando ...
                                        </button>
                                        <div v-if="sent" class="mt-2" >
                                            Seus dados foram enviados com sucesso. Iremos lhe manter a par de qualquer novidade através do seu contacto.
                                        </div>
                                        <div
                                            v-if="!loading && problemSending"
                                            class="mt-2"
                                            style="color: red" >
                                            Seus dados não foram submetidos, tente novamente.
                                        </div>
                                    </div>
                                </div>
                                <div class="form-response" ></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact Page Area End Here -->
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    export default {
        name: "ContactSectionComponent",
        data () {
            return {
                formData: {
                    name: '',
                    email: '',
                    telephone: '',
                    subject: '',
                    message: ''
                },
                sent: false,
                problemSending: false
            }
        },

        computed: {
            ...mapState(['loading'])
        },

        methods: {
            ...mapActions(['saveData']),

            handleSaveData () {
                this.saveData(this.formData).then(response => {
                    if (response) {
                        this.sent = true
                    } else {
                        this.problemSending = true
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>