<template>
    <div>

        <page-title-component
                title="Quatro formas de receber um novo colaborador"
                subtitle="Um Blog apenas"
                :secondSection="{
                title: 'Blog',
                link: '/blog',
            }"
        />

        <!-- Blog Area Start Here -->
        <section class="single-blog-wrap-layout1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-12">
                        <div class="single-blog-box-layout1">
                            <div class="post-img">
                                <img :src="blogPost.img" style="width: 100%" alt="Blog">
                            </div>
                            <div class="item-content">
                                <div class="post-tag gradient-accent">Artigo</div>
                                <h2 class="item-title">
                                    {{ blogPost.title }}
                                </h2>
                                <div class="entry-content">
                                    <ul class="entry-meta">
                                        <li><i class="far fa-user-circle"></i>NORB</li>
                                        <li><i class="far fa-clock"></i>25 Outubro de 2020</li>
                                    </ul>

                                </div>
                                <p>
                                    Com base na experiência dos nossos colaboradores, seguem-se algumas dicas já experimentadas e testadas sobre como manter a produtividade no trabalho remoto.
                                </p>

                                <ul class="list-item">
                                    <li><i class="fas fa-check-circle"></i>
                                        <b> Defina um horário </b> – Começar a horas e definir um horário ajuda muito à autodisciplina, mas também reforça a concentração. A sua família e amigos devem também estar conscientes do seu horário de trabalho.
                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Reduza as distracções </b> – Sejam elas os seus filhos, animais ou telemóvel. Esta última pode ser enganadora – mantenha-se afastado do telemóvel, pois é muito
                                        fácil perder a noção do tempo quando começa a ver o seunews feed.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Não trabalhe em pijama, distinga o seu lado trabalhador do seu lado de descanso </b> – Também não há necessidade de usar o seu melhor fato, mas vestir algo confortável e apresentável ajudá-lo-á a separar o seu lado trabalhador do seu lado de descanso. Segundo a Dr. Karen Pine, que dá aulas de psicologia da moda “Quando vestimos uma peça de roupa, é comum adoptarmos as características associadas a essa roupa. Há muita roupa com significado simbólico para nós, seja o nosso traje profissional ou a ‘roupa de descanso do fim de semana’. Como tal, quando a vestimos, preparamos o nosso cérebro para agir de acordo com ela.”

                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Mantenha uma rotina matinal </b> – Uma das maiores vantagens de trabalhar a partir de casa é dedicar menos tempo à sua rotina matinal. Além disso, também ganha tempo ao não ter de se deslocar até ao trabalho. No entanto, tal não deve ser interpretado como uma falta de rotina.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Tenha um espaço de trabalho permanente </b> – Assim que decidir qual a melhor divisão para trabalhar, limpe-a e arrume-a regularmente. Invista numa boa iluminação e cores que o inspirem no local.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Saia de casa </b> – Ter disciplina não significa que tenha de ficar em casa e sentir-se como se estivesse numa prisão. Dê uma volta ou vá almoçar fora com um amigo, tal como faria no escritório.

                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> Socialize </b> – Trabalhar a partir de casa pode ser solitário. Saia com amigos depois do trabalho ou vá ao escritório de tempos a tempos cumprimentar os seus
                                        colegas. Participe em conferências e eventos que lhe permitam conhecer novas pessoas.
                                    </li>

                                </ul>

                                <p class="mt-5">
                                    Trabalhar a partir de casa é, para muitos, um sonho tornado realidade e não é de admirar. Posto isto, se está a considerar seriamente um trabalho remoto, terá de se esmerar ao nível da autodisciplina para manter a satisfação com o trabalho e progredir na carreira. A boa notícia é que existem actualmente centenas de empregadores que permitem o trabalho flexível e, como tal, inúmeras oportunidades por onde escolher. Deixe de sonhar e procure o seu trabalho flexível agora!
                                </p>

                            </div>
                            <div class="item-tag">
                                <ul>
                                    <li><i class="fas fa-tags"></i><a href="#">Blog,</a></li>
                                    <li><a href="#">Dicas</a></li>
                                    <!--<li><a href="#">Optimization</a></li>-->
                                </ul>
                            </div>
                            <div class="blog-author">
                                <div class="media media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog25.jpg" class="media-img-auto" alt="Lora Zaman">
                                    </div>
                                    <div class="media-body media-none-sm">
                                        <h4 class="item-title">{{ blogAuthor.name }}</h4>
                                        <div class="item-subtitle">Author</div>
                                        <p>{{ blogAuthor.description }}</p>
                                        <ul class="item-social">
                                            <li><a href="#" class="bg-fb"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#" class="bg-twitter"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#" class="bg-linked"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#" class="bg-gplus"><i class="fab fa-google-plus-g"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="pagination-layout3">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="prev-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure8.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#"><i class="fas fa-chevron-left"></i>Previous Post</a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="next-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure9.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body mg-right text-right">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#">Next Post<i class="fas fa-chevron-right"></i></a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->

                        </div>
                    </div>

                    <div class="col-lg-4" >
                        <blog-side-nav-component class="col-lg-12" />
                    </div>

                </div>
            </div>
        </section>
        <!-- Blog Area End Here -->

    </div>
</template>

<script>

    import { mapState } from 'vuex'

    import PageTitleComponent from "../../components/base/PageTitleComponent";
    import SingleBlogComponent from "../../components/blog/SingleBlogComponent";
    import BlogSideNavComponent from "../../components/blog/BlogSideNavComponent";
    export default {
        name: "BlogDicasTrabalharAPartirDeCasaPage",
        components: {
            BlogSideNavComponent,
            SingleBlogComponent,
            PageTitleComponent
        },
        computed: {
            ...mapState([
                'blogs',
                'authors'
            ]),
            blogLink () {
                return this.$route.fullPath
            },
            blogPost () {
                return this.blogs.find(b => b.link === this.blogLink)
            },
            blogAuthor () {
                return this.authors.find(author => author.id === this.blogPost.author_id)
            }
        },
        filters: {
            limitLength (str, max) {
                return str.length > max ? str.substr(0, max) + '...' : str
            }
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        },

    }
</script>

<style scoped>

</style>