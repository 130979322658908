<template>
    <div>

        <page-title-component
            title="Blog"
            subtitle="Notícias e novidades"
        />

        <base-blog-component />

    </div>
</template>

<script>

    import PageTitleComponent from "../components/base/PageTitleComponent";
    import AboutSectionOneComponent from "../components/about/AboutSectionOneComponent";
    import AboutSectionTwoComponent from "../components/about/AboutSectionTwoComponent";
    import TeamAreaComponent from "../components/TeamAreaComponent";
    import TestemonialComponent from "../components/TestemonialComponent";
    import BaseBlogComponent from "../components/blog/BaseBlogComponent";
    export default {
        name: "BlogPage",
        components: {
            BaseBlogComponent,
            PageTitleComponent
        },
        mounted () {
            this.scrollToTop ()
        },
        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        }
    }
</script>

<style scoped>

</style>