<template>
    <!-- About Us Area Start Here -->
    <section class="about-wrap-layout5">
        <div class="about-box-layout7">
            <div class="single-item left-side-content">
                <div class="item-content">
                    <h2 class="item-title">Nossa missão</h2>
                    <p>
                        Ser o ponto de encontro para o sucesso no mercado moçambicano, no qual as empresas encontram os talentos adequados para as suas vagas, e os candidatos acham as melhores empresas e oportunidades para alcançar o êxito profissional.
                    </p>
                    <h2 class="item-title">
                        As nossas garantias
                    </h2>
                    <ul class="service-list">
                        <li>
                            Substituição de profissionais em caso de falta de acordo ou de interrupção do contrato de
                            trabalho durante os primeiros meses;
                        </li>
                        <li> Assistência Constante com vista a oferecer um serviço de alta qualidade.</li>
                        <!--<li>Retain, high-paying customers</li>-->
                        <!--<li>Phenomenal success in our target</li>-->
                    </ul>
                </div>
            </div>
            <div class="single-item right-side-content bg-common" data-bg-image="img/about/about1.jpg">
                <div class="item-content">
                    <h2 class="item-title">Nossa Experiência</h2>
                    <div class="progress-box">
                        <div class="progress">
                            <div class="lead">Recrutamento</div>
                            <div style="width: 100%; visibility: visible;"
                                 data-progress="100%" class="progress-bar">
                                <span>100%</span></div>
                        </div>
                        <div class="progress">
                            <div class="lead">Selecção</div>
                            <div style="width: 99%; visibility: visible;"
                                 data-progress="99%" class="progress-bar">
                                <span>100%</span></div>
                        </div>
                        <div class="progress">
                            <div class="lead">Alocação</div>
                            <div style="width: 99%; visibility: visible;"
                                 data-progress="99%" class="progress-bar">
                                <span>100%</span></div>
                        </div>
                        <div class="progress">
                            <div class="lead">Formação de Equipas</div>
                            <div style="width: 98%; visibility: visible;"
                                 data-progress="98%" class="progress-bar">
                                <span>100%</span></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- About Us Area End Here -->
</template>

<script>
    export default {
        name: "AboutSectionTwoComponent"
    }
</script>

<style scoped>

</style>