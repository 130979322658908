<template>
	<div>
		<page-title-component
            :title="title"
            subtitle="Um Blog apenas"
            :secondSection="{
            	title: 'Blog',
            	link: '/blog',
        	}"
        />

        <section class="single-blog-wrap-layout1" >
            <div class="container" >
                <div class="row" >
                    <div class="col-lg-8 col-12" >
                    	<div class="single-blog-box-layout1">
                            <div class="post-img" >
                                <img :src="blog.img" style="width: 100%" alt="Blog">
                            </div>
                            <div class="item-content" >
                                <div class="post-tag gradient-accent" >Artigo</div>
                                <h2 class="item-title" >
                                    {{ blog.title }}
                                </h2>
                                <div class="entry-content" >
                                    <ul class="entry-meta" >
                                        <li><i class="far fa-user-circle" ></i>NORB</li>
                                        <li><i class="far fa-clock" ></i>25 Outubro de 2020</li>
                                    </ul>
                                </div>

                                <!-- blog content -->
                                <blog-content-list-component
                                	:blog="parseInt(blogId)"
                                >
                                </blog-content-list-component>
                                <!-- (end) blog content -->

                                <br>
                            </div>
                            <div class="item-tag" >
                                <ul>
                                    <li><i class="fas fa-tags" ></i><a href="#" >Blog,</a></li>
                                    <li><a href="#" >Dicas</a></li>
                                </ul>
                            </div>
                            <div class="blog-author" >
                                <div class="media media-none--sm" >
                                    <div class="item-img" >
                                        <img src="img/logo.png" class="media-img-auto" alt="Norb" style="height: 50px; width: auto" >
                                    </div>
                                    <div class="media-body media-none-sm" >
                                        <h4 class="item-title" >{{ blogAuthor.name }}</h4>
                                        <div class="item-subtitle" >Autor</div>
                                        <p>{{ blogAuthor.description }}</p>
                                        <ul class="item-social" >
                                            <li><a href="#" class="bg-fb" ><i class="fab fa-facebook-f" ></i></a></li>
                                            <li><a href="#" class="bg-twitter" ><i class="fab fa-twitter" ></i></a></li>
                                            <li><a href="#" class="bg-linked" ><i class="fab fa-linkedin-in" ></i></a></li>
                                            <li><a href="#" class="bg-gplus" ><i class="fab fa-google-plus-g" ></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4" >
                        <blog-side-nav-component class="col-lg-12" />
                    </div>
                </div>
            </div>
        </section>
	</div>
</template>

<script>
	import PageTitleComponent from "../components/base/PageTitleComponent"
	import BlogSideNavComponent from "../components/blog/BlogSideNavComponent"
	import BlogContentListComponent from "../components/blog/BlogContentListComponent"

	import { mapState } from 'vuex'

	export default {
		name: "BlogDetailsPage",
		components: {
			PageTitleComponent,
			BlogSideNavComponent,
			BlogContentListComponent
		},

		computed: {
			...mapState([
				'blogs',
				'authors'
			]),

			blogId () {
				return this.$route.params.blogId
			},

			blog () {
				return this.blogs.find(blog => blog.id === parseInt(this.blogId))
			},

			title () {
				return this.blogs.find(blog => blog.id === parseInt(this.blogId)).title.toLowerCase()
			},

			blogAuthor () {
                return this.authors.find(author => author.id === this.blog.author_id)
            }
		}
	}
</script>