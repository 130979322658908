<template>
    <div
        class="col-lg-4 col-12 sidebar-widget-area sidebar-break-md"
        style="
            position: -webkit-sticky;
            position: sticky;
            top: 0
        " >
        <div class="widget widget-search-box" >
            <div class="input-group stylish-input-group" >
                <input type="text" class="form-control" placeholder="Search Here . . ." >
                <span class="input-group-addon" >
                    <button type="submit" >
                        <span class="flaticon-magnifying-glass" aria-hidden="true" ></span>
                    </button>
                </span>
            </div>
        </div>
        <div class="widget widget-related-case" >
            <div class="heading-layout5 mb-5" >
                <h3>Recent Posts</h3>
            </div>

            <div
                v-for="(blog, index) in blogs.slice(0, 3)"
                :key="index"
                class="media media-none--xs"
            >
                <div class="item-img">
                    <img :src="blog.img" style="width: 100px;height: 100px" class="media-img-auto" alt="case study">
                </div>
                <div class="media-body space-md">
                    <div class="case-date">25 Outubro 2020</div>
                    <h5 class="item-title">
                        <router-link :to="blog.link">
                            {{ blog.title | limitLength(32) }}
                        </router-link>
                    </h5>
                </div>
            </div>

        </div>

    </div>
</template>

<script>

    import { mapState } from 'vuex'

    export default {
        name: "BlogSideNavComponent",
        computed: {
            ...mapState([
                'blogs'
            ])
        },
        filters: {
            limitLength (str, max) {
                return str.length > max ? str.substr(0, max) + '...' : str
            }
        }
    }
</script>

<style scoped>

</style>