<template>
    <div>

        <page-title-component
                title="Quatro formas de receber um novo colaborador"
                subtitle="Um Blog apenas"
                :secondSection="{
                title: 'Blog',
                link: '/blog',
            }"
        />

        <!-- Blog Area Start Here -->
        <section class="single-blog-wrap-layout1">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-12" >
                        <div class="single-blog-box-layout1">
                            <div class="post-img">
                                <img :src="blogPost.img" style="width: 100%" alt="Blog">
                            </div>
                            <div class="item-content">
                                <div class="post-tag gradient-accent">Artigo</div>
                                <h2 class="item-title">
                                    {{ blogPost.title }}
                                </h2>
                                <div class="entry-content">
                                    <ul class="entry-meta">
                                        <li><i class="far fa-user-circle"></i>NORB</li>
                                        <li><i class="far fa-clock"></i>25 Outubro de 2020</li>
                                        <!--<li><i class="far fa-comment-dots"></i>13</li>-->
                                    </ul>
                                    <!--<ul class="item-social">-->
                                        <!--<li><a href="#" class="bg-fb"><i class="fab fa-facebook-f"></i></a></li>-->
                                        <!--<li><a href="#" class="bg-twitter"><i class="fab fa-twitter"></i></a></li>-->
                                        <!--<li><a href="#" class="bg-gplus"><i class="fab fa-google-plus-g"></i></a></li>-->
                                        <!--<li><a href="#" class="bg-linked"><i class="fab fa-linkedin-in"></i></a></li>-->
                                        <!--<li><a href="#" class="bg-pin"><i class="fab fa-pinterest"></i></a></li>-->
                                        <!--<li><a href="#" class="text-black"><i class="fas fa-plus"></i></a></li>-->
                                    <!--</ul>-->
                                </div>
                                <p>
                                    Receber uma nova contratação na equipa é uma oportunidade empolgante para todos os envolvidos. Afinal, esta pessoa é dona de um conhecimento, competências e experiência que podem ajudar a sua empresa a prosperar. É por isso que é importante que a sua nova contratação não só seja bem recebida, como também sinta que é bem-vinda a partir do momento em que entra no escritório. Tenha em mente as seguintes cinco dicas:
                                </p>

                                <ul class="list-item">
                                    <li><i class="fas fa-check-circle"></i>
                                        <b> 1. Envie um email personalizado ao novo membro da sua equipa.</b> Os RH irão, provavelmente, enviar um email padrão ao novo colaborador, mas é
                                        simpático da sua parte enviar-lhe também um email. Comece por dizer como você e a sua equipa estão entusiasmados por receber um novo membro. Em seguida, fale sobre o que está a fazer e como a sua experiência será uma mais-valia para o projecto. Por fim, convide o seu novo colaborador a dirigir- se a si em caso de dúvida ou preocupações.
                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>
                                        <b> 2. Prepare um horário de observação rotativo.</b> Caso o tempo e a política da empresa assim o permitam, poderá ser útil colocar o novo colaborador a observar cada um dos seus colegas de equipa durante um dia, pois acção poderá ajudar a nova contratação a entender melhor as funções dos colegas na equipa.
                                    </li>
                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> 3. Designe um mentor.</b> Deverá designar um colaborador mais experiente que possa ensinar o novo funcionário a aprender e assimilar a cultura da empresa. Lembre-se que, segundo o artigo de Susan M. Heathfield “How to Welcome a New Employee” do

                                        The Balance, a nova contratação não deve responder directamente ao mentor.
                                    </li>

                                    <li class="mt-5"><i class="fas fa-check-circle"></i>

                                        <b> 4. Organize um evento social para toda a equipa. </b>
                                        No primeiro dia ou algures durante a primeira semana, convide os seus colaboradores para um evento social divertido, como um almoço, jantar ou encontro depois do trabalho. O novo membro da equipa terá, assim, a oportunidade de conhecer melhor os seus colegas — e vice-versa.

                                    </li>

                                </ul>

                                <p class="mt-5">
                                    Ajudar o novo colaborador a sentir-se bem-vindo desde o primeiro momento, é muito importante para que este se sinta valorizado e envolvido. Por estas razões, vale sempre a pena o esforço no sentido de garantir que os primeiros dias de trabalho de uma nova contratação são uma experiência positiva.
                                </p>

                            </div>
                            <div class="item-tag">
                                <ul>
                                    <li><i class="fas fa-tags"></i><a href="#">Blog,</a></li>
                                    <li><a href="#">Dicas</a></li>
                                    <!--<li><a href="#">Optimization</a></li>-->
                                </ul>
                            </div>
                            <div class="blog-author">
                                <div class="media media-none--sm">
                                    <div class="item-img">
                                        <img src="img/blog/blog25.jpg" class="media-img-auto" alt="Lora Zaman">
                                    </div>
                                    <div class="media-body media-none-sm">
                                        <h4 class="item-title">{{ blogAuthor.name }}</h4>
                                        <div class="item-subtitle">Author</div>
                                        <p>{{ blogAuthor.description }}</p>
                                        <ul class="item-social">
                                            <li><a href="#" class="bg-fb"><i class="fab fa-facebook-f"></i></a></li>
                                            <li><a href="#" class="bg-twitter"><i class="fab fa-twitter"></i></a></li>
                                            <li><a href="#" class="bg-linked"><i class="fab fa-linkedin-in"></i></a></li>
                                            <li><a href="#" class="bg-gplus"><i class="fab fa-google-plus-g"></i></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <!--<div class="pagination-layout3">-->
                                <!--<div class="row">-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="prev-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure8.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#"><i class="fas fa-chevron-left"></i>Previous Post</a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                    <!--<div class="col-6">-->
                                        <!--<div class="next-btn">-->
                                            <!--<div class="media media-none&#45;&#45;lg">-->
                                                <!--<div class="item-img">-->
                                                    <!--<a href="#"><img src="img/figure/figure9.jpg" class="media-img-auto" alt="Prev"></a>-->
                                                <!--</div>-->
                                                <!--<div class="media-body mg-right text-right">-->
                                                    <!--<h5 class="item-title">How an Island Formsn And Stones</h5>-->
                                                    <!--<div class="item-date"><i class="far fa-clock"></i>July 15, 2019</div>-->
                                                    <!--<a href="#">Next Post<i class="fas fa-chevron-right"></i></a>-->
                                                <!--</div>-->
                                            <!--</div>-->
                                        <!--</div>-->
                                    <!--</div>-->
                                <!--</div>-->
                            <!--</div>-->

                        </div>
                    </div>

                    <div class="col-lg-4" >
                        <blog-side-nav-component class="col-lg-12" />
                    </div>

                </div>
            </div>
        </section>
        <!-- Blog Area End Here -->

    </div>
</template>

<script>

    import { mapState } from 'vuex'

    import PageTitleComponent from "../../components/base/PageTitleComponent";
    import SingleBlogComponent from "../../components/blog/SingleBlogComponent";
    import BlogSideNavComponent from "../../components/blog/BlogSideNavComponent";
    export default {
        name: "BlogQuatroFormasDeReceberPage",
        components: {
            BlogSideNavComponent,
            SingleBlogComponent,
            PageTitleComponent
        },

        computed: {
            ...mapState([
                'blogs',
                'authors'
            ]),

            blogLink () {
                return this.$route.fullPath
            },

            blogPost () {
                return this.blogs.find(b => b.link === this.blogLink)
            },

            blogAuthor () {
                return this.authors.find(author => author.id === this.blogPost.author_id)
            }
        },

        filters: {
            limitLength (str, max) {
                return str.length > max ? str.substr(0, max) + '...' : str
            }
        },

        mounted () {
            this.scrollToTop ()
        },

        methods: {
            scrollToTop () {
                var body = $("html, body");
                body.stop().animate({ scrollTop:0 }, 500, 'swing', function() {});
            }
        },

    }
</script>

<style scoped>

</style>