<template>
    <!-- Why Choose Area Start Here -->
    <section class="why-chose-wrap-layout4">
        <div class="container">

            <div class="row gutters-50" >
                <div class="col-xl-6 col-12">
                    <div class="why-chose-box-layout6">
                        <div class="item-img">
                            <img src="img/service/service5.jpg" alt="Why Choose Us">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 order-xl-1">
                    <div class="why-chose-box-layout6 mgt-xl-50">
                        <div class="item-content">

                            <h2 class="item-title">
                                SETORES DE ESPECIALIZAÇÃO
                            </h2>

                            <h4>
                                CONHECEMOS O SEU NEGÓCIO.
                            </h4>
                            <p>
                                As especificidades de determinadas indústrias exigem respostas à medida.
                            </p>

                            <p>
                                Na Norbestamos prontos a dar a melhor resposta e os melhores profissionais para o negócio ter sucesso.
                            </p>

                            <p>
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td scope="col">Administração pública</td>
                                            <td scope="col">indústria</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">Agricultura</td>
                                            <td>Saúde</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">contactcenters</td>
                                            <td>technologies</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">Eventos</td>
                                            <td>oil&GAs</td>
                                        </tr>
                                        <tr>
                                            <td scope="row">Hotelaria e turismo</td>
                                            <td>Educação</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row gutters-50" >
                <div class="col-xl-6 col-12">
                    <div class="why-chose-box-layout6">
                        <div class="item-img">
                            <img src="img/service/service7.jpg" alt="Why Choose Us">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 order-xl-1">
                    <div class="why-chose-box-layout6 mgt-xl-50">
                        <div class="item-content">
                            <h2 class="item-title">
                                RECRUTAMENTO & SELECAO
                            </h2>
                            <p>
                                A metodologia da Norbvisa identificar com qualidade e eficiência as necessidades de nossos clientes, entendo o seu mercado de atuação, para que possamos selecionar profissionais alinhados a estratégias da organização.
                            </p>

                            <p>
                                <b>Diferenciais</b><br><br>

                                <ul>
                                    <li>15 Anos de experiencia nu mercado</li>
                                    <li>Equipe de psicólogos para análise comportamental</li>
                                    <li>Avaliação de inteligência emocional e competências</li>
                                    <li>Checagem de Referencias profissionais</li>
                                    <li>Garantias de reposição</li>
                                    <li>Maior Banco de Dados de currículos</li>
                                    <li>Abrangência Nacional</li>
                                </ul>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <!-- -->
            <div class="row gutters-50" >
                <div class="col-xl-6 col-12">
                    <div class="why-chose-box-layout6">
                        <div class="item-img">
                            <img src="img/service/service4.jpg" alt="Why Choose Us">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 order-xl-1">
                    <div class="why-chose-box-layout6 mgt-xl-50">
                        <div class="item-content">
                            <h2 class="item-title">
                                Soluções flexíveis a par das suas necessidades
                            </h2>
                            <p>
                                <p>
                                    A Norbestabelece uma ponte entre as empresas que pretendem fazer contratações temporárias com a nossa rede de contactos de potenciais trabalhadores interessados em funções temporárias ou projetos de duração limitada.
                                </p>

                                <p>
                                    Reduzir os custos fixos, poupar tempo no recrutamento e contratação, assegurar a flexibilidade necessária na sequência de substituição de colaboradores, projetos especiais ou períodos de pico de produção, são as principais vantagens deste serviço. 
                                </p>

                                <p>
                                    A Norbassegura toda a gestão contratual e salarial, o cumprimento da lei, das obrigações fiscais e uma faturação sem falhas nos prazos estabelecidos.
                                </p>

                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row gutters-50" >
                <div class="col-xl-6 col-12">
                    <div class="why-chose-box-layout6">
                        <div class="item-img">
                            <img src="img/service/service8.jpg" alt="Why Choose Us">
                        </div>
                    </div>
                </div>
                <div class="col-xl-6 col-12 order-xl-1">
                    <div class="why-chose-box-layout6 mgt-xl-50">
                        <div class="item-content">
                            <h2 class="item-title">
                                Serviço Onsite
                            </h2>
                            <p>
                                <p>
                                    Com experiência comprovada no mercado de grande volume, somos líderes na solução de gestão Onsite em Mocambique.
                                </p>

                                <p>
                                    A NORB é especializada em recrutamento de grandes volumes de trabalhadores temporários, a partir das instalações da sua empresa. Destacamos equipas que se dedicam exclusivamente a cada cliente, proporcionando-lhes uma gestão mais eficiente dos trabalhadores temporários, que se traduzirá numa menor rotatividade e, consequentemente, numa maior produtividade.
                                </p>

                                <p>
                                    A Norbé certificada pelo Sistema de Gestão da Qualidade no âmbito do recrutamento, seleção e gestão de trabalhadores temporários de acordo com o referencial NP EN ISO 9001:2008.
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Why Choose Area End Here -->
</template>

<script>
    export default {
        name: "ServicesListComponent"
    }
</script>

<style scoped>

</style>