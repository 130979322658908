<template>
    <div class="col-12" >
        <div
            class="blog-box-layout4 mt-4 py-4"
            style="
                max-width: 700px; border: 1px solid #E0E0E0;
                border-radius: 4px; max-height: 750px
            " >
            <div>

                <div class="row px-5" >
                    <div class="col-xs-3 col-sm-2" >
                        <div
                            style="
                                border-radius: 50%;
                                width: 50px;
                                height: 50px;
                                background-image: url(https://cdn.quasar.dev/img/boy-avatar.png);
                                background-size: cover
                            "
                        >
                        </div>
                    </div>
                    <div class="col-xs-9 col-md-10" >
                        <b>Norb</b><br>
                        Publicado no dia 15/03/2021
                    </div>
                </div>

                <div class="px-4" >
                    <hr>
                </div>

                <router-link :to="blog.link" >
                    <div class="item-img text-center px-4" style="max-height: 400px" >
                        <img :src="blog.img" alt="blog" width="100%" height="200" >
                    </div>
                </router-link>

                <div class="item-content" >
                    <ul class="entry-meta">
                        <li><i class="fas fa-calendar-alt"></i>25 Outubro 2020</li>
                        <li><i class="fas fa-user"></i>NORB</li>
                    </ul>
                    <h3 v-if="blog.link" class="item-title" >
                        <router-link :to="blog.link" >
                            {{ blog.title | limitLength(37)}}
                        </router-link>
                    </h3>
                    <p v-if="blog.smallDesc" >
                        {{ blog.smallDesc.slice(0, 80) }} ...
                    </p>
                    <router-link :to="blog.link" class="ghost-btn-2 border-accent text-accent">
                        Ler mais <i class="fas fa-arrow-circle-right"></i>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        name: "BlogComponent",
        props: ['blog'],

        filters: {
            limitLength (str, max) {
                return str.length > max ? str.substr(0, max) + '...' : str
            }
        }
    }
</script>

<style scoped>

</style>