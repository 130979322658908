<template>
	<div style="height: 450px" >
		<ul
			v-for="user in users" :key="user.id"
			class="list-group"
		>
			<router-link :to="'/user/' + user.id" >
				<div
					class="list-group-item mt-2"
					style="cursor: pointer; color: black"
				>
					<i class="far fa-envelope-open" ></i> {{ user.email }}
					| <span class="text-red" >{{ readableDate(user.created_at) }}</span>
				</div>
			</router-link>
		</ul>

		<div
			v-if="loadingMore"
			style="height: 50px"
			class="mt-3 d-flex align-items-center justify-content-center" >
			Carregando ...
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex'
    import moment from 'moment'

	export default {
		name: "ListUsersComponent",
		props: ['users'],

		computed: {
			...mapState(['loadingMore'])
		},

		methods: {
			readableDate (date) {
				return moment(date).format('DD-MM-YYYY hh:mm')
			}
		}
	}
</script>