<template>
	<div>

		<template v-if="blog === 1" >
			<p>
                Receber uma nova contratação na equipa é uma oportunidade empolgante para todos os envolvidos. Afinal, esta pessoa é dona de um conhecimento, competências e experiência que podem ajudar a sua empresa a prosperar. É por isso que é importante que a sua nova contratação não só seja bem recebida, como também sinta que é bem-vinda a partir do momento em que entra no escritório. Tenha em mente as seguintes cinco dicas:
            </p>

            <ul class="list-item" >
                <li>
                	<i class="fas fa-check-circle" ></i>
                    <b> 1. Envie um email personalizado ao novo membro da sua equipa.</b> Os RH irão, provavelmente, enviar um email padrão ao novo colaborador, mas é
                    simpático da sua parte enviar-lhe também um email. Comece por dizer como você e a sua equipa estão entusiasmados por receber um novo membro. Em seguida, fale sobre o que está a fazer e como a sua experiência será uma mais-valia para o projecto. Por fim, convide o seu novo colaborador a dirigir- se a si em caso de dúvida ou preocupações.
                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>
                    <b> 2. Prepare um horário de observação rotativo.</b> Caso o tempo e a política da empresa assim o permitam, poderá ser útil colocar o novo colaborador a observar cada um dos seus colegas de equipa durante um dia, pois acção poderá ajudar a nova contratação a entender melhor as funções dos colegas na equipa.
                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> 3. Designe um mentor.</b> Deverá designar um colaborador mais experiente que possa ensinar o novo funcionário a aprender e assimilar a cultura da empresa. Lembre-se que, segundo o artigo de Susan M. Heathfield “How to Welcome a New Employee” do

                    The Balance, a nova contratação não deve responder directamente ao mentor.
                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>
                    <b> 4. Organize um evento social para toda a equipa.</b>
                    No primeiro dia ou algures durante a primeira semana, convide os seus colaboradores para um evento social divertido, como um almoço, jantar ou encontro depois do trabalho. O novo membro da equipa terá, assim, a oportunidade de conhecer melhor os seus colegas — e vice-versa.
                </li>
            </ul>

            <p class="mt-5" >
                Ajudar o novo colaborador a sentir-se bem-vindo desde o primeiro momento, é muito importante para que este se sinta valorizado e envolvido. Por estas razões, vale sempre a pena o esforço no sentido de garantir que os primeiros dias de trabalho de uma nova contratação são uma experiência positiva.
            </p>			
		</template>

		<template v-if="blog === 2" >
			<p>
                Com base na experiência dos nossos colaboradores, seguem-se algumas dicas já experimentadas e testadas sobre como manter a produtividade no trabalho remoto.
            </p>

            <ul class="list-item" >

                <li><i class="fas fa-check-circle" ></i>
                    <b> Defina um horário </b> – Começar a horas e definir um horário ajuda muito à autodisciplina, mas também reforça a concentração. A sua família e amigos devem também estar conscientes do seu horário de trabalho.
                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Reduza as distracções </b> – Sejam elas os seus filhos, animais ou telemóvel. Esta última pode ser enganadora – mantenha-se afastado do telemóvel, pois é muito
                    fácil perder a noção do tempo quando começa a ver o seunews feed.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Não trabalhe em pijama, distinga o seu lado trabalhador do seu lado de descanso </b> – Também não há necessidade de usar o seu melhor fato, mas vestir algo confortável e apresentável ajudá-lo-á a separar o seu lado trabalhador do seu lado de descanso. Segundo a Dr. Karen Pine, que dá aulas de psicologia da moda “Quando vestimos uma peça de roupa, é comum adoptarmos as características associadas a essa roupa. Há muita roupa com significado simbólico para nós, seja o nosso traje profissional ou a ‘roupa de descanso do fim de semana’. Como tal, quando a vestimos, preparamos o nosso cérebro para agir de acordo com ela.”

                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Mantenha uma rotina matinal </b> – Uma das maiores vantagens de trabalhar a partir de casa é dedicar menos tempo à sua rotina matinal. Além disso, também ganha tempo ao não ter de se deslocar até ao trabalho. No entanto, tal não deve ser interpretado como uma falta de rotina.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Tenha um espaço de trabalho permanente </b> – Assim que decidir qual a melhor divisão para trabalhar, limpe-a e arrume-a regularmente. Invista numa boa iluminação e cores que o inspirem no local.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Saia de casa </b> – Ter disciplina não significa que tenha de ficar em casa e sentir-se como se estivesse numa prisão. Dê uma volta ou vá almoçar fora com um amigo, tal como faria no escritório.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Socialize </b> – Trabalhar a partir de casa pode ser solitário. Saia com amigos depois do trabalho ou vá ao escritório de tempos a tempos cumprimentar os seus
                    colegas. Participe em conferências e eventos que lhe permitam conhecer novas pessoas.
                </li>

            </ul>

            <p class="mt-5" >
                Trabalhar a partir de casa é, para muitos, um sonho tornado realidade e não é de admirar. Posto isto, se está a considerar seriamente um trabalho remoto, terá de se esmerar ao nível da autodisciplina para manter a satisfação com o trabalho e progredir na carreira. A boa notícia é que existem actualmente centenas de empregadores que permitem o trabalho flexível e, como tal, inúmeras oportunidades por onde escolher. Deixe de sonhar e procure o seu trabalho flexível agora!
            </p>
		</template>

		<template v-if="blog === 3" >
			<p class="mt-5" >
                Um excelente CV ainda é um dos maiores factores para conseguir um bom trabalho. Afinal de contas, apresenta aos recrutadores e potenciais empregadores uma visão clara e rápida das suas capacidades, experiência e formação relevantes para que decidam convidá-lo para uma entrevista ou não. E se bem-sucedido, ajuda a balancear a primeira de muitas decisões ao procurar trabalho.
            </p>

            <p class="mt-5" >
                No entanto, não importa quão boas são as suas referências, se não tiver um bom CV para as acompanhar, provavelmente a sua candidatura será rejeitada ao candidatar-se a uma nova função. Considere isto: de acordo com um estudo do The Ladders, os recrutadores passam apenas seis segundos a ver um CV. Isso significa que tem apenas uma pequena janela de oportunidade para causar uma
                boa impressão! Por outras palavras, necessita de um CV que se destaque em todos os sentidos. Iremos ajuda-lo a preparar um CV que demonstra quais os factores a dar importância.
            </p>

            <ul class="list-item" >
                <li>
                	<i class="fas fa-check-circle" ></i>

                    <b> Restrinja o seu CV. </b> Os recrutadores vão procurar no seu CV informação relevante, assim sendo, apresente os seus dados de forma simplificada, em vez de utilizar parágrafos longos. Utilize palavras-chave relevantes para a profissão e experiência completa. Por exemplo, se for gestor de projecto, use palavras-chave como “gestão de projectos,” “supervisão,” e “liderança.”

                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> O seu CV não deve exceder duas páginas. </b> Poucos recrutadores têm tempo para ver CVs muito longos. Assim sendo, mesmo que tenha mais experiência do que a que cabe em duas páginas, deve utilizar a informação mais relevante. Remova palavras e considere omitir um ou dois dos seus trabalhos mais recentes ou condense as descrições das suas responsabilidades em cada uma das posições.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Não se afaste muito do formato convencional de CV. </b> Quase todas as candidaturas actualmente passam por sistemas de registo e avaliação de candidaturas. Isso significa que o seu CV será primeiramente verificado por um computador antes de ser visto por uma pessoa. Os sistemas de controlo de candidatos são projectados para determinar certos dados, incluindo dados pessoais, objectivo de carreira, capacidades, experiência e formação, assim sendo, certifique-se que enumera claramente todos estes pontos. Informação
                    adicional como Associações Profissionais, Prémios e Publicações é opcional. Inclua-as unicamente se sentir que ajudará na candidatura.
                </li>


                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Forneça uma visão concisa do seu objectivo de carreira. </b> No artigo do USA Today “5 regras básicas para um bom CV,” Patrick O' Brien recomenda descrever o que quer realizar profissionalmente de forma a ilustrar o que pode
                    fazer pelo empregador. Por exemplo, se for um gestor que pretende ganhar experiência internacional, poderia indicar algo como: “gestor altamente capaz com capacidades de liderança excepcionais e uma compreensão global do sector.”
                </li>

                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Não inclua demasiada informação pessoal. </b> No artigo da CBS “Como redigir um CV": regras básicas”, Suzanne Lucas alerta para o perigo de incluir informação tal como religião, data de nascimento, estado civil, passatempos ou ligações a páginas de redes sociais.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Liste resultados quantificáveis. </b> Potenciais empregadores querem ver realizações profissionais no seu CV e a maneira mais simples de as comunicar
                    é usando números, sempre que possível.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Liste a sua experiência em ordem cronológica inversa, </b> começando pelo seu
                    último emprego. Liste primeiro o último trabalho e vá andando para trás. Se não tiver espaço suficiente para listar todos os trabalhos que realizou, liste apenas os últimos 10 anos e prepare-se para falar sobre trabalhos mais antigos numa entrevista pessoal.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b>Não exagere nas funções, responsabilidades ou resultados obtidos. </b> Mencione toda a informação de forma correta, sem exageros ou artifícios. Lembre-se: a maioria dos empregadores verifica referências e vão perguntar sobre o seu desempenho em trabalhos anteriores.

                </li>
                <li class="mt-5" >
                	<i class="fas fa-check-circle" ></i>

                    <b> Certifique-se que tem versões electrónicas e em papel do seu CV. </b> É uma boa ideia ter o seu CV em vários formatos diferentes, incluindo PDF imprimível, PDF interactivo, Word e em texto, no caso de necessitar de inserir mais informação.

                </li>

            </ul>

            <p class="mt-5" >
                <b>Não se esqueça de actualizar regularmente o seu CV.</b> Mesmo que não esteja à procura de trabalho de forma activa, nunca se sabe quando alguém pode pedir o seu CV, assim sendo, certifique-se que o mantém actualizado. Dessa forma, quando ouvir falar de um trabalho interessante num evento de networking, estará pronto para se candidatar imediatamente.
            </p>
            <p class="mt-5" >
                Redigir um CV eficaz leva tempo e necessita de preparação. Mas com estas regras básicas em mente, aumentará as possibilidades de se destacar de outros candidatos, conseguindo obter uma entrevista para um novo trabalho.
            </p>
		</template>

		<template v-if="blog === 4" >
			<p>
				Em um mercado cada vez mais competitivo, contratar profissionais de qualidade e que atendam às expectativas da empresa é imprescindível. Mas afinal, como reduzir o custo do processo de recrutamento e seleção e manter a excelência nas contratações?
			</p>

			<p>
				É um objetivo comum a muitas empresas que desejam contar com profissionais capazes de contribuir para elevar os resultados, sem ter gastos excessivos e altas despesas com o recrutamento, que podem ser prejudicais ao planejamento financeiro.
			</p>

			<p>
				Nesse contexto, contratar com eficiência e baixo custo muitas vezes representa um grande desafio para a equipe de Recursos Humanos. Para ajudar você a atingir os objetivos na sua empresa, confira dicas valiosas para reduzir o custo do processo de recrutamento e seleção. Boa leitura!
			</p>
		</template>

		<template v-if="blog === 5" >
			
			<p>
				O processo de recrutamento e seleção exige o investimento em divulgações, dinâmicas, testes e entrevistas até encontrar o perfil desejado pela empresa. Ainda assim, é natural que a contratação não saia como deveria, necessitando de novos gastos para preencher as vagas.
			</p>

			<p>
				Além disso, a dificuldade para encontrar o perfil ideal, falta de recursos e planejamento podem estender os processos de recrutamento, tornando-os mais caros. Por esses motivos, as empresas acabam gastando valores desnecessários nas contratações.
			</p>
		</template>

		<template v-if="blog === 6" >
			<p>
				Ter processos seletivos bem planejados para buscar talentos no mercado com métodos otimizados e custos reduzidos é indispensável para o negócio. Para tanto, é preciso contar com as estratégias corretas e aprimorar a gestão dos Recursos Humanos.
			</p>

			<p>
				Veja dicas importantes para reduzir custos no recrutamento e seleção!
			</p>

			<b>CONTE COM UMA CONSULTORIA ESPECIALIZADA</b>
			
			<p>
				Uma ótima alternativa para reduzir custos com o recrutamento e seleção é contar com o apoio de uma consultoria especializada. Além de ter diretrizes eficientes para encontrar talentos, a consultoria tem expertise para mapear perfis de acordo com as necessidades da empresa, reduzindo o trabalho e os custos.
			</p>

			<b>FAÇA BOAS DIVULGAÇÕES</b>

			<p>
				O processo de divulgação é determinante para encontrar profissionais com agilidade. Sendo assim, é importante divulgar em todos os meios de comunicação, apresentando as características do cargo e da empresa.
			</p>

			<b>INVISTA EM TECNOLOGIA </b>

			<p>
				A tecnologia é uma grande aliada para diversas atividades profissionais, inclusive para fazer o recrutamento e seleção. Com o uso de recursos tecnológicos, você pode agilizar as etapas e fazer uma análise de perfil comportamental eficiente, evitando perder horas com processos manuais e burocráticos.
			</p>

			<b>POR QUE ISSO É FUNDAMENTAL PARA OS RESULTADOS DA EMPRESA?</b>

			<p>
				Os custos com demissões e contratação de novos funcionários causam impactos significativos e inesperados no planejamento financeiro da empresa. São problemas que comprometem as receitas e causam desgastes no clima organizacional.
			</p>

			<p>
				Por essas razões, investir em técnicas de gestão para reduzir custos com o recrutamento e seleção é muito importante, pois permite otimizar o orçamento e direcionar os recursos para o planejamento estratégico do RH, investindo na evolução e no engajamento da equipe de trabalho.
			</p>

			<p>
				Como você percebeu, com um bom planejamento é possível reduzir o custo do processo de recrutamento e seleção. Além de economizar nas contratações, isso aumenta a possibilidade de ter profissionais com o perfil ideal para a empresa, evitando despesas com demissões e novas seleções.
			</p>

			<p>
				Gostou deste post? Se você deseja saber mais sobre o assunto, não perca tempo! Baixe agora mesmo nosso e-book que fala sobre por que contratar uma consultoria de RH e veja mais um conteúdo relevante para o RH da sua organização!
			</p>
		</template>

		<template v-if="blog === 7" >

			<p>
				Não é fácil encontrar metais preciosos como o ouro e a prata. Mesmo quando são identificados, é preciso usar técnicas para separá-los de detritos e aproveitá-los na fabricação de objetos caros. Podemos comparar o valor das habilidades soft skills e hard skills com o do ouro e da prata, pois se trata de algo muito desejado no mundo corporativo.
			</p>

			<p>
				Neste artigo, abordaremos o conceito das soft skills e hard skills. Além disso, vamos apontar as mais cobiçadas pelas corporações atuais. Acompanhe!
			</p>
		</template>

		<template v-if="blog === 9" >
			<p>
				As habilidades hard skills são antigas conhecidas dos recrutadores, pois aparecem de forma recorrente nos currículos dos profissionais. Essas competências são aprendidas por meio de uma formação acadêmica ou outro modo de aprendizado, portanto são fáceis de identificar e mensurar.
			</p>

			<p>
				Por outro lado, as soft skills se referem às características individuais. Na maioria das vezes, são inseridas no nosso DNA e não precisamos aprendê-las com métodos de ensino. Diferentemente das hard skills, elas são difíceis de reconhecer e quantificar, podendo ficar escondidas devido à falta de oportunidades para demonstrá-las.
			</p>

			<p>
				No entanto, quando a empresa tem uma gestão eficiente das habilidades dos colaboradores, é possível encontrar essas “peças de ouro” e usá-las nas estratégias do negócio. O resultado é um time que entrega todo o seu potencial e, por isso, trabalha feliz para alcançar as metas empresariais.
			</p>
		</template>

		<template v-if="blog === 10" >

			<p>
				Um estudo apresentado pela Global Challenge Insight Report mostrou que, na era da Inteligência Artificial, as habilidades serão curingas para os profissionais manterem seus postos de trabalho. Mas quais são as soft skills e hard skills mais procuradas? Veja a seguir.
			</p>

			SENSO DE LIDERANÇA
			<p>
				O senso de liderança é uma soft skill bastante valorizada. O profissional que a tem consegue gerenciar pessoas ao mesmo tempo em que as motiva a darem o seu melhor. Para isso, são necessárias duas outras competências: empatia e facilidade para lidar com outros.
			</p>

			CONHECIMENTO DE LÍNGUAS
			<p>
				Vivemos em um mundo globalizado e conectado, o que permite às empresas fazer negócios e manter escritórios em todas as partes do planeta. Essa realidade exige que os profissionais tenham uma hard skill: o conhecimento de línguas. Quanto mais idiomas uma pessoa falar, maior será seu acesso aos empregos melhores.
			</p>

			TRABALHO EM EQUIPE
			<p>
				Muitas empresas adotaram a cultura colaborativa como um dos pilares de suas identidades. Além disso, várias tecnologias fomentam o conceito de crowdsourcing (desenvolvimento de projetos com a colaboração de vários profissionais). Essa tendência torna claro que a habilidade de trabalhar em equipe é fundamental nas organizações atuais.
			</p>

			ESPECIALIZAÇÕES
			<p>
				Como vimos, as especializações fazem parte das hard skills. Atualmente, o colaborador precisa adquirir conhecimentos durante todo o tempo. O conceito chamado “lifelong learning” (aprendizagem contínua) tem sido utilizado por muitas organizações, o que mostra que a atualização por meio de especializações destaca os profissionais.
			</p>

			PENSAMENTO CRÍTICO
			<p>
				Em uma entrevista para a revista Exame, Giedre Vasiliauskaite (professora da Universidade de Rotterdam) apontou o pensamento crítico como uma das habilidades mais importantes do século XXI. Essa soft skill envolve a capacidade de visualizar um desafio por ângulos diferentes e não comuns.
			</p>
		</template>
 
		<template v-if="blog === 11" >

			<p>
				Atualmente, existem ferramentas virtuais que auxiliam no reconhecimento de competências. Por meio de testes, os candidatos demonstram o nível de suas habilidades. Após isso, um relatório é produzido para que os gestores tenham conhecimento sobre o perfil do profissional.
			</p>

			<p>
				Essas tecnologias devem ser acompanhadas pelo direcionamento de especialistas na área de recrutamento e seleção. Sendo assim, a contratação de uma empresa de consultoria em RH é essencial para que a gestão de competências seja consolidada na companhia.
			</p>

			<p>
				Gostou do nosso conteúdo? Entendeu de quais soft skills e hard skills sua empresa mais precisa? 
			</p>
		</template>

 		<template v-if="blog === 12" >

			<p>
				O recrutamento é um dos processos mais importantes das empresas de todo o mundo. É um fator decisivo, que determina o crescimento ou a falha de um negócio. Além de envolver recursos necessários para recrutar profissionais competentes, é preciso se certificar de que sua abordagem é adequada para essa finalidade. Para isso, vamos tratar do recrutamento e seleção por competências.
			</p>

			<p>
				Profissionais precisam ter motivação e facilidade de adaptação à cultura da empresa. Se algum desses critérios não for atendido, a contratação vai estar destinada ao fracasso. Há necessidade de um método de recrutamento que permita aos profissionais de RH avaliar candidatos de forma justa.
			</p>

			<p>
				Siga com a leitura e descubra como funciona esse método e quais são seus diferenciais.
			</p>

			<b>O QUE É RECRUTAMENTO E SELEÇÃO POR COMPETÊNCIAS?</b>

			<p>
				O método com base em competências é um conceito de empresas que se popularizou nos últimos anos. O objetivo dessa técnica é descrever a qualidade de uma empresa em uma área específica.
			</p>

			<p>
				Competências foram adotadas por pequenas e grandes companhias para informar no que elas são boas. O propósito desse método é identificar os melhores currículos para contratar candidatos aptos para tal função e setor.
			</p>

			<p>
				Em um contexto de recrutamento, competências são definidas como características comportamentais, traços de personalidade, conhecimentos, habilidades e qualificações dos candidatos. O empregador deve definir requisitos de competência para cada vaga em diferentes níveis, como setor, departamento e cargo.
			</p>

			<p>
				Primeiro, a empresa identifica as competências relevantes para alcançar seus objetivos. Em seguida, ela comunica esses requisitos aos candidatos em potencial, para chamar a atenção dos candidatos fortes.
			</p>

			<p>
				Depois, eles usam exemplos da experiência profissional para demonstrar que cumprem os requisitos. Essa etapa costuma acontecer nos primeiros momentos da seleção ou na fase de entrevista. Depende da maneira como profissionais de RH organizam o processo seletivo.
			</p>

			<b>QUAIS OS BENEFÍCIOS DESSE TIPO DE RECRUTAMENTO E SELEÇÃO?</b>

			<p>
				O recrutamento baseado em competências foi apresentado para substituir a abordagem tradicional de contratação, que costumava ser muito problemática. É comum encontrar empresas que falham em identificar o método de recrutamento ideal.
			</p>

			<p>
				Nelas, não há definições claras das metas no recrutamento relacionadas à avaliação dos candidatos e decisões de seleção. Ou seja, empresas que não utilizam o poder das competências têm dificuldade para localizar candidatos talentosos. No entanto, recrutadores que se concentram nelas podem se beneficiar de diversas maneiras.
			</p>

			<p>
				Definir metas é fundamental para o recrutamento baseado em competências. É uma forma de unir estratégias das empresas e de recrutamento com decisões de contratação.
			</p>

			<p>
				É importante ter uma maneira bem estruturada e coerente para definir os requisitos e diferenciais dos candidatos nas vagas. Os profissionais vão se sentir mais seguros e confiantes para se inscrever nas vagas certas, de acordo com sua formação acadêmica e suas experiências anteriores. Isso é o que justifica empresas de todo o mundo terem adotado essa metodologia de recrutamento.
			</p>

			<b>QUAIS OS RECURSOS UTILIZADOS NESSE MÉTODO DE RECRUTAMENTO?</b>

			<p>
				Há muitos recursos que são aplicados no processo seletivo do recrutamento e seleção por competências. Análise do currículo, entrevistas, dinâmicas de grupo e testes psicológicos são indispensáveis nesse momento de seleção.
			</p>

			<p>
				O início de todo o processo se dá pela análise de currículo. Nesse arquivo, estão expostas as formações e experiências profissionais dos candidatos. Essa fase é crucial, pois os Recrutadores precisam levar em consideração as competências no momento de decidir quais candidatos vão dar continuidade ao processo seletivo, avançando para as próximas etapas.
			</p>

			<p>
				Cada fase tem sua relevância para que recrutadores identifiquem o perfil de cada candidato e reconheçam seus conhecimentos, experiências e aptidões. Nas entrevistas, recrutadores analisam como os candidatos se comportam quando estão na empresa, além de postura e uso de vocabulário.
			</p>

			<p>
				É o momento de dialogar sobre escolhas, desafios e experiências. Eles devem explicar apropriadamente o motivo de ter escolhido a vaga e a empresa. Já as dinâmicas e testes psicológicos são úteis para avaliar a reação e o comportamento deles, cooperando com outros candidatos.
			</p>

			<b>COMO ESSE MODELO SE DIFERE DOS TRADICIONAIS?</b>

			<p>
				Incorporar competências no recrutamento da sua empresa pode ser muito útil e resultar em melhorias significativas. Porém, quando falamos em resultados, tudo se resume à forma de execução. Desde o projeto de um método sob medida para suas necessidades até a implementação dessa abordagem feita pelos gerentes de recrutamento e seleção.
			</p>

			<p>
				É essencial que empresas utilizem metas bem definidas e um método de avaliação rigoroso. Isso permite que empregadores contratem funcionários que terão um desempenho melhor e vão permanecer na empresa por mais tempo. Muitas organizações renomadas, que utilizam esta metodologia em seus processos, veem melhorias no indicador-chave de desempenho de recrutamento.
			</p>

			<p>
				Isso, justamente, após a implementação do método baseado em competência. Além disso, empregadores encontram profissionais eficientes por meio de entrevistas de competência e oferecem aos candidatos oportunidades iguais. Por fim, minimizar o preconceito é uma ótima maneira de contratar candidatos de diversas regiões, tratá-los com igualdade e criar um ambiente profissional inclusivo.
			</p>

			<b>QUAIS AS VANTAGENS DE CONTAR COM UMA EMPRESA ESPECIALIZADA?</b>

			<p>
				Além das facilidades oferecidas por esse método, há benefícios que facilitam as tarefas dos recrutadores. Por exemplo, redução de custos e contratações inadequadas, otimização do tempo, redução do turnover e processo de contratação consistente. São características que não estão presentes em métodos tradicionais de recrutamento e seleção.
			</p>

			<p>
				Mas você sabia que há mais vantagens contando com uma ajuda especializada? A Norb pode ajudar sua empresa a focar resultados e agilizar processos, especialmente, em processos de recrutamento e seleção. Não somente esses, mas também, consultoria de RH, treinamentos para líderes e equipes, avaliação de desempenho e planejamento estratégico de RH.
			</p>

			<p>
				Se você considera que sua empresa tem boas estratégias de RH, experimente contar com um parceiro especializado. Você vai se surpreender com a qualidade dos nossos serviços. Será a grande chance de sua empresa estruturar uma equipe com os melhores profissionais do mercado de trabalho.
			</p>

			<p>
				O recrutamento e seleção por competências é totalmente diferente dos modelos tradicionais. Selecionar os melhores profissionais com base nas suas necessidades é fundamental para o sucesso do seu negócio. Devemos contratar de forma diversa, equitativa e inclusiva para manter a ética da empresa. E claro, contar com a ajuda dos serviços da Norb para sua empresa ter uma equipe de sucesso e muito produtiva.
			</p>

			<p>
				Gostou de conhecer os diferenciais desse método de recrutamento? Então, não mais perca mais tempo e entre em contato conosco! Temos excelentes soluções para sua empresa.
			</p>
		</template>

		<template v-if="blog === 13" >

			<p>
				O processo seletivo remoto se popularizou há algum tempo devido ao avanço das tecnologias de comunicação pela internet. Ele era comumente empregado nos processos de de recrutamento e seleção em que havia uma distância física significativa entre o recrutador e o candidato que se desejava contratar e/ou entrevistar.
			</p>

			<p>
				No entanto, mais recentemente com as mudanças provocadas pelo Covid 19, o processo seletivo remoto tornou-se uma necessidade imperativa. Em um cenário onde o isolamento social e o trabalho home office são preponderantes, o processo seletivo remoto veio como uma solução viável e eficaz.
			</p>

			<p>
				Quer saber mais sobre esse assunto? Acompanhe esse texto e conheça como essa prática pode ser realizada pela empresa e por que essa é uma abordagem que veio para ficar. Confira! 
			</p>

			<b>MOTIVOS PARA REALIZAR CONTRATAÇÕES REMOTAMENTE</b>
			
			<p>
				Como vimos anteriormente, há situações em que devido a impossibilidade da presença física do candidato na sede da empresa, a realização da seleção remota se torna necessária. No entanto, existem outras vantagens que estão inerentes a esse processo. Vejamos a seguir.
			</p>

			<b>FACILIDADE DE GERENCIAMENTO DO PROCESSO</b>

			<p>
				Como é realizado totalmente por meio tecnológico, o gerenciamento e condução do processo é bem mais ágil do que o presencial.
			</p>

			<p>
				Isso porque o setor de RH pode se munir de plataformas e softwares que centralizam todo o escopo do projeto desde a fase de divulgação da vaga até a entrevista, incluindo inclusive a parte de digitalização da documentação do candidato aprovado.
			</p>

			<b>MAIS INSUMOS PARA AVALIAR O CANDIDATO</b>

			<p>
				O processo seletivo remoto também permite oferecer inúmeras informações sobre o candidato, tal qual o modelo tradicional/presencial. Isso porque quando o candidato se inscreve ele deverá fornecer informações que podem ser utilizadas como base para o mapeamento comportamental dele.
			</p>

			<p>
				É o caso, por exemplo, da empresa utilizar informações das redes sociais para avaliar se os traços e comportamentos do entrevistado estão de acordo com as diretrizes da empresa.
			</p>

			<b>CONSTRUIR UM BANCO DE TALENTOS</b>

			<p>
				O processo seletivo remoto pode ser suficiente para resolver uma situação de contratação atual, mas também para contratações futuras. Sabemos que no caso especial do cenário de pandemia é algo que tem uma duração limitada.
			</p>

			<p>
				Portanto, com o fim dessa situação a empresa poderá retornar a normalidade das suas operações. Para essa retomada, ela pode utilizar o banco de talentos já formado durante o processo seletivo remoto.
			</p>

			<b>A EFICIÊNCIA DO PROCESSO SELETIVO REMOTO</b>

			<p>
				Muito se questiona se os processos seletivos de forma remota podem manter a mesma qualidade dos processos presenciais. A resposta é afirmativa. Isso porque nesse processo seletivo ainda estarão vigentes os aspectos para avaliar o candidato da versão presencial.
			</p>

			<p>
				Por exemplo, ele deverá ser pontual no momento da entrevista, deverá se portar de maneira adequada diante a câmera do seu dispositivo de vídeo e ter o cuidado de ser entrevistado em um lugar reservado. 
			</p>

			<p>
				Esse processo não apenas está de acordo com as demandas atuais como também prepara a empresa para um futuro próximo. Com avanço da tecnologia e o processo de globalização vai se tornar cada vez mais comum a necessidade de utilizar tecnologias de videoconferência e banco de dados informacionais para contratação de novos colaboradores.
			</p>

			<p>
				Essas foram algumas informações sobre processo seletivo remoto. Para que esse processo seja mais eficiente é importante considerar a contratação de uma empresa de recrutamento e seleção que tenha vasta experiência no mercado. Essa empresa terá a responsabilidade de realizar todo o processo de divulgação da vaga, triagem de currículos e entrevista.
			</p>

			<p>
				Quer conhecer uma empresa com esse potencial? Entre em contato com a Norb. Reunimos as melhores estratégias para a sua organização contratar os melhores do mercado.
			</p>
		</template>

		<template v-if="blog === 14" >

			<p>
				A integração de funcionários, também chamada de ambientação, é um processo fundamental ao fazer novas contratações. Integrar colaboradores significa muito mais do que apresentar as metas, regras e metodologias da empresa em questão, mas, também, cumpre o importante papel de fazer que com eles se sintam verdadeiramente bem-vindos e inseridos no novo time.
			</p>

			<p>
				Um bom processo de integração é o segundo passo (depois do processo seletivo) para direcionar as equipes para o alcance dos objetivos das empresas por meio da adaptação do funcionário à organização. Isso acontece porque é algo que cria um senso de envolvimento e pertencimento, o que está relacionado ao grau de comprometimento e desempenho que esse novo colaborador terá em seu novo cargo.
			</p>

			<p>
				A seguir, descubra como essa integração de funcionários pode ser feita e quais são os seus melhores resultados.
			</p>

			<b>COMO TRABALHAR A INTEGRAÇÃO DE FUNCIONÁRIOS?</b>

			<p>
				Integrações podem ser feitas por meio de diversos recursos e técnicas. São duas as palavras-chave para uma integração de sucesso:
			</p>

			<ul>
				<li>
					•	conteúdo – deve ser de alta qualidade e extremamente alinhado à realidade da empresa;
				</li>
				<li>
					•	interação – o novo funcionário deve ter liberdade total para tirar suas dúvidas e expor seus pontos de vista, o que pode ser uma ótima oportunidade para a área de Recursos Humanos colher feedbacks para aplicar em suas estratégias.
				</li>
			</ul>

			<br><br>

			A apresentação de integração deve conter informações importantes como:

			<br><br>

			<ul>
				<li>•	a missão, visão e valores da empresa;</li>
				<li>•	os objetivos e desafios em vista;</li>
				<li>•	exemplos de comportamentos, tanto os incentivados, quanto os não tolerados no ambiente de trabalho;</li>
				<li>•	direitos e deveres dos funcionários;</li>
				<li>•	esclarecimentos sobre o uso dos espaços físicos, benefícios, planos de carreira e processos;</li>
				<li>•	descrição do(s) processo(s) de avaliação de desempenho e da estrutura organizacional;</li>
				<li>•	informação sobre os canais de comunicação disponíveis para quando o colaborador desejar tirar dúvidas, fazer sugestões e assim por diante.</li>
			</ul>

			<p>
				Não se esqueça de elaborar um plano de ação específico para cada setor da empresa ou atividade do funcionário. Uma abordagem diferenciada e direcionada é muito mais eficiente para gerar a desejada atuação em equipe.
			</p>

			<b>QUAIS SÃO AS TÉCNICAS POSSÍVEIS?</b>

			<p>
				Cada empreendimento tem uma necessidade. Então, há várias formas de integrar funcionários e garantir que eles atuem da melhor maneira. Dependendo das exigências, é possível reunir mais de um método em busca dos melhores resultados.
			</p>

			<p>
				Faça uma análise sobre quais são os gargalos do setor em questão e entenda qual é a abordagem exigida pelo perfil profissional. Assim, dá para encontrar a melhor combinação.
			</p>

			<p>
				A seguir, veja quais são as possibilidades a serem consideradas.
				<br><br>
				
				<b>UTILIZAR ONBOARDING</b>
				<br><br>

				O onboarding é um processo de entrada que garante que o colaborador se adapte à nova equipe, bem como às suas responsabilidades e direitos.

				<p>
					Para tanto, é fundamental criar um procedimento claro e direto, com os materiais corretos. É válido oferecer treinamentos, cultura e códigos de conduta e até uma lista de tarefas. A integração deve acontecer de forma progressiva para que, ao final, ele esteja adaptado à nova função e ao ambiente de trabalho.
				</p>
			</p>

			<b>POR QUE VER O PROCESSO DE INTEGRAÇÃO COMO INVESTIMENTO?</b>

			<p>
				Algumas empresas ainda não veem o procedimento de integração como investimento. Mas o que pode ser mais valioso do que o comprometimento das equipes?
			</p>

			<p>
				O mais enriquecedor de um processo de integração é que é revelado ao novo colaborador o quanto ele é importante na nova organização que o acolhe. Além disso, ele percebe o interesse em que ele tenha acesso a todas as informações e ferramentas necessárias para executar seu trabalho da melhor forma.
			</p>

			<p>
				Como resultado, empregado e empresa saem ganhando! Ao final, o comprometimento e a produtividade de um profissional sem dúvida são impulsionados com um processo de integração bem feito, o que não necessariamente envolve altos custos.
			</p>

			<p>
				A integração de funcionários é uma etapa essencial para garantir que os novos colaboradores atuem corretamente. Além disso, é um jeito de melhorar os relacionamentos e fortalecer a atuação, o que exige a escolha da técnica de integração adequada.
			</p>

			<p>
				Para estar sempre atualizado com o setor de Recursos Humanos, acompanhe nossos perfis no Facebook, no LinkedIn e no Instagram.
			</p>
		</template>

 		<template v-if="blog === 15" >

			<p>
				Você sabe o que é um headhunter e qual a sua importância para as empresas? Esses profissionais podem ajudar a encontrar os melhores talentos do mercado, para cargos desafiadores e de grande responsabilidade dentro da organização.
			</p>

			<p>
				Encontrar esses profissionais estratégicos é um grande desafio. Uma contratação eficiente pode representar grandes saltos na produtividade e, principalmente, trazer inovação para os processos da empresa, melhorando a competitividade no mercado.
			</p>

			<p>
				Neste artigo, vamos abordar as atribuições do headhunter, quais as suas formas de trabalho e como ele pode ajudar a formar boas equipes de trabalho. Continue lendo para conhecer mais sobre esse profissional. Vamos lá?
			</p>

			<b>O QUE É UM HEADHUNTER?</b>

			<p>
				Um headhunter é um caçador de talentos que pode atuar como freelancer ou dentro de uma empresa especializada. Ele se dedica a encontrar candidatos com um perfil muito específico no mercado e que geralmente trabalham em outras empresas.
			</p>

			<p>
				O trabalho do headhunter tem foco na busca de talentos com experiência e habilidades muito específicas exigidas por seus clientes. Na maioria das vezes, as vagas as quais eles preenchem são de cargos executivos ou de alto nível de responsabilidade nas organizações, como diretoria, presidência, entre outras.
			</p>

			<b>COMO O PROFISSIONAL HEADHUNTER TRABALHA?</b>

			<p>
				A metodologia em um processo de seleção de headhunting vai muito além da análise de uma lista interminável de currículos. O headhunter realiza uma pesquisa ativa para localizar o candidato que melhor se adapte às necessidades do cliente. Como geralmente são perfis muito difíceis de encontrar, os headhunters entram em contato com profissionais que trabalham em empresas do setor solicitado.
			</p>

			<p>
				O candidato buscado deve ser ideal não apenas para o trabalho, mas também deve haver um alinhamento com os valores da empresa e com as equipes de trabalho já formadas. Os clientes geralmente exigem candidatos com alta capacidade de liderança e uma ampla rede de contatos.
			</p>

			<p>
				Para garantir a eficiência do seu trabalho, os headhunters estão sempre à frente das tendências do mercado de trabalho, mantendo-se sempre atualizados sobres as novidades da área de recrutamento e seleção e dos perfis que as empresas estão procurando. Além disso, eles são profissionais muito bem relacionados e possuem um vasto networking para auxiliar na busca por profissionais.
			</p>

			<b>POR QUE CONTRATAR UM HEADHUNTER?</b>
			<p>
				Como já dissemos, encontrar profissionais estratégicos para compor as equipes de trabalho é vital para o sucesso de uma organização. A busca por esses profissionais é desafiadora, já que eles geralmente estão empregados e não serão encontrados em sites de empregos.
			</p>

			<p>
				Portanto, se sua empresa deseja contratar um profissional diferenciado, experiente e com espírito inovador para ocupar um cargo de grande responsabilidade, a melhor forma de encontrar os candidatos ideais é com a ajuda de um headhunter.
			</p>

			<p>
				Essa também é uma boa saída quando se precisa preencher uma vaga com urgência, sem tempo para as etapas de atração. O caçador de talentos ajuda a acelerar o processo e fechar a contratação em tempo hábil. Enquanto os profissionais de RH atuam como recrutadores, divulgando vagas e recebendo currículos para triagem, o headhunter atua como um verdadeiro caçador, vasculhando o mercado até encontrar o profissional ideal.
			</p>

			<p>
				Agora que você já sabe o que é um headhunter e o que ele faz, indicamos que você analise as necessidades da sua empresa e identifique se é necessário contratar esse profissional para ajudar com a contratação de um cargo de confiança. Não há uma única resposta certa, o importante é escolher a melhor opção para a organização.
			</p>
		</template>

		<template v-if="blog === 16" >

			<p>
				Contratar um novo colaborador é um grande desafio para os profissionais de recursos humanos. O processo de recrutamento e seleção deve ser planejado passo a passo e com precisão, desde a decisão do perfil do profissional desejado até a assinatura do contrato de trabalho.
			</p>

			<p>
				São várias etapas muito importantes e que fazem a diferença no resultado final. Entre elas, vários tipos de entrevista de emprego que têm uma importância vital e, na grande maioria das vezes, é com ela que define quem será contratado. Por isso é preciso ter muita atenção e saber qual a melhor abordagem, de acordo com cada situação, para se alcançar um resultado eficaz.
			</p>

			<p>
				Quer conhecer os tipos de entrevista mais utilizados pelas empresas na escolha do candidato ideal? Continue a leitura!
			</p>

			<b>ENTREVISTA POR COMPETÊNCIAS</b>

			<p>
				A entrevista por competência é a mais usada atualmente, quando a busca por talentos se tornou uma prioridade para qualquer recrutador que se preze. Trata-se de saber reconhecer as habilidades e aptidões dos candidatos para sua subsequente adaptação ao trabalho – criatividade, agilidade mental, liderança, etc.
			</p>

			<p>
				Atualmente, as empresas desejam incorporar em sua estrutura profissionais que possam não apenas desempenhar bem um determinado trabalho, mas também profissionais que façam parte de uma força de trabalho capaz de lidar com os desafios e mudanças constantes da atualidade.
			</p>

			<b>ENTREVISTA SITUACIONAL</b>

			<p>
				A maioria das entrevistas desse estilo visa avaliar a capacidade do candidato de reagir a diferentes situações que podem ocorrer no dia-a-dia de um trabalho, nas quais o controle das emoções, a criatividade e a empatia, entre outras habilidades comportamentais, é fundamental.
			</p>

			<p>
				Passa isso, as perguntas simulam situações cotidianas do trabalho, fazendo com que o candidato conte como reagiria a elas ou como já reagiu, caso tenha passado pela situação.
			</p>

			<b>ENTREVISTA COLETIVA</b>
			<p>
				Esse é um tipo de entrevista de emprego bem comum. trata-se de colocar vários candidatos em uma mesma sala e fazer as perguntas publicamente, passando a vez para cada um responder. O objetivo é aprofundar a análise de cada aspirante enquanto se observa a interação entre os diferentes profissionais.
			</p>

			<b>ENTREVISTA INDIVIDUAL</b>

			<p>
				Trata-se de uma reunião entre duas pessoas: o candidato e um representante da empresa, normalmente do departamento de Recursos Humanos, para conhecer a idoneidade do seu perfil. A intimidade deste formato permite ao entrevistado maior tranquilidade para se abrir e falar de suas habilidades e aspirações.
			</p>

			<b>ENTREVISTA ESTRUTURADA</b>

			<p>
				Como o nome indica, tudo o que acontece na reunião é programado e estudado, a sequência de perguntas, o tom da conversa, os diferentes tópicos a serem tratados, etc. Nessas entrevistas, não há espaço para improvisos ou personalização, pois todos os candidatos passam exatamente pelo mesmo processo.
			</p>

			<p>
				Visto dessa perspectiva, tudo parece muito simples e pouco atraente, no entanto, esse método é de fundamental importância quando se pretende fazer uma análise da eficiência da entrevista, visando promover melhorias em processos seletivos futuros.
			</p>

			<b>ENTREVISTA POR CONFERÊNCIA</b>

			<p>
				É comum hoje em dia que a maior parte do recrutamento aconteça a distância e, felizmente, contamos com ferramentas cada vez mais eficientes para esse fim. Geralmente a primeira entrevista do candidato acontece por aplicativos como o Skype ou o Zoom, que permitem interação online em tempo real. Elas também podem ser realizadas por telefone ou chat, a critério do entrevistador.
			</p>

			<p>
				Não existe entrevista melhor ou mais eficiente. Tudo depende do contexto em que ela é realizada, do porte da empresa, da quantidade de candidatos, das características e responsabilidades do cargo entre outros fatores que devem ser considerados no momento da escolha.
			</p>

			<p>
				Cabe ao RH, juntamente com os gestores envolvidos na contratação, decidir o tipo de entrevista de emprego mais adequado para cada situação. Nada impede que diferentes tipos de entrevista de emprego sejam utilizados em um mesmo processo, com os mesmos candidatos.
			</p>

			<p>
				Agora que você já está por dentro dos vários tipos de entrevistas, aproveite e descubra como conduzir boas entrevistas em processos seletivos.
			</p>
		</template>

		<template v-if="blog === 17" >

			<p>
				Você sabe como direcionar as perguntas para entrevista de desligamento? Essa é uma ferramenta importante, utilizada com um trabalhador que teve o vínculo empregatício com a organização interrompido, seja por decisão dele ou da empresa.
			</p>

			<p>
				Sem dúvida, é um momento favorável para obter informações relevantes sobre os motivos que levaram a pessoa a deixar seu trabalho ou sua visão com relação a demissão. Dessa forma, pode-se levantar possíveis pontos de melhoria, ajudando a otimizar a retenção de talentos.
			</p>

			<p>
				Cada ferramenta de gestão tem uma importância decisiva no sucesso da formação e manutenção das equipes de trabalho. Quer entender um pouco mais sobre a entrevista de desligamento? Continue com a gente!
			</p>

			<b>QUAL O OBJETIVO DA ENTREVISTA DE DESLIGAMENTO?</b>

			<p>
				A entrevista de desligamento pode ter dois objetivos: comunicar a demissão ao funcionário e seus motivos, ou saber por que ele decidiu sair e quais informações relevantes ele pode nos transmitir sobre o funcionamento interno da empresa.
			</p>

			<p>
				A chave é que, quando o trabalhador desfaz o vínculo com a empresa, ele pode se sentir muito mais inclinado a falar francamente sobre o que não está indo bem no dia a dia da organização. Esse é o verdadeiro valor das entrevistas de saída: conhecer, de um ponto de vista qualificado, os processos internos e o que deve ser trabalhado para melhorar a dinâmica das equipes de trabalho.
			</p>

			<p>
				Por meio das entrevistas de desligamento, as organizações podem praticar a arte de conhecer seu ativo mais importante: seu capital humano. Assim, fica mais fácil criar as estratégias necessárias para a retenção de talentos ou para melhorar a produtividade de uma empresa.
			</p>

			<b>QUAIS AS PRINCIPAIS PERGUNTAS PARA ENTREVISTA DE DESLIGAMENTO?</b>

			<p>
				As perguntas feitas em uma entrevista de desligamento devem ter como objetivo entender os motivos da quebra do vínculo entre trabalhador e empresa. Nesse sentido, algumas questões relevantes a serem abordadas seriam:
			</p>

			<ul>
				<li>•	há quanto tempo o trabalhador pensa em deixar a empresa;</li>
				<li>•	razões da saída (motivos salariais, desmotivação, oferta mais atraente, plano de carreira, problemas com colegas ou superiores, etc);
				</li>
				<li>•	grau de satisfação do trabalhador durante o desenvolvimento de seu trabalho na empresa;</li>
				<li>•	saber se o trabalhador recomendaria a empresa como um bom local para se trabalhar;</li>
				<li>•	melhorias recomendadas para a empresa;</li>
				<li>•	pontos fortes e fracos da organização e</li>
				<li>•	relações entre colegas e superiores da empresa entre outras questões.</li>
			</ul>

			<p>
				É importante deixar espaço para o trabalhador fornecer informações que não foram solicitadas por meio das perguntas.
			</p>

			<b>COMO APROVEITAR OS RESULTADOS DA ENTREVISTA DE DESLIGAMENTO?</b>

			<p>
				Essa entrevista deve ser realizada pelo setor de Recursos Humanos da empresa. É importante que a pessoa que a realiza seja objetiva e não tenha um relacionamento direto com o trabalhador que está de saída, evitando possíveis parcialidades. É fundamental que se alcance um clima de confiança e colaboração, pois, dessa forma, pode-se obter informações realmente úteis e confiáveis.
			</p>

			<p>
				Também é importante considerar o momento em que a entrevista será feita. O ideal é esperar um ou dois dias após a notícia da demissão, pois, no calor do momento, as respostas podem vir carregadas de sentimentos que distorçam a percepção do colaborador e a eficácia da entrevista.
			</p>

			<p>
				O mais importante, no entanto, é que as respostas colhidas de cada colaborador desligado não sejam apenas mais um arquivo com informações não utilizadas. Esses dados são uma base essencial para elaboração de um plano de ação, visando executar melhorias nos relacionamentos e na comunicação empresarial e, assim, aumentar a produtividade e a competitividade da organização.
			</p>

			<p>
				Esperamos ter ajudado você entender a importância das perguntas para entrevista de desligamento. Gostou do nosso conteúdo? Fique por dentro das novidades acompanhando nossas redes sociais. Estamos no LinkedIn, Facebook e Instagram.
			</p>

		</template>

		<template v-if="blog === 18" >

			<p>
				A entrevista de competência é uma metodologia que pode ser usada para otimizar os processos seletivos. Seu principal objetivo é mensurar as habilidades de cada candidato ao cargo e, assim, fazer a melhor escolha.
			</p>

			<p>
				É o caso de analisar o nível de conhecimento em um idioma, procedimento ou recurso. A partir disso, obter o melhor alinhamento. Embora a premissa seja simples, muitos gestores de RH ainda têm dificuldade de incorporar o processo. Afinal, ele deve ser implementado de forma altamente estratégica.
			</p>

			<p>
				Para que não restem dúvidas, é preciso explorar a entrevista de competência como ferramenta de seleção e saber utilizá-la. Continue com a leitura e entenda como!
			</p>

			<b>AS VANTAGENS DO RECRUTAMENTO POR ESSE MÉTODO</b>

			<p>
				Um dos aspectos mais relevantes de adotar essa abordagem é a chance de criar uma seleção objetiva. Ao conhecer as competências importantes e fazer a análise dos perfis, fica fácil contratar a pessoa que se adéqua à vaga. Isso aumenta as chances de conquistar resultados melhores no futuro.
			</p>

			<p>
				Também é um modo de otimizar a aderência do novo contratado e, portanto, de diminuir a rotatividade. É algo que fortalece a coesão e a atividade do time, já que pode demorar até 2 anos para um novo colaborador se ambientar totalmente e alcançar a produtividade do restante do grupo.
			</p>

			<p>
				Com processos mais simples e efetivos, os custos diminuem e a atuação passa a ser especialmente estratégica.
			</p>

			<b>AS COMPETÊNCIAS QUE DEVEM SER AVALIADAS</b>

			<p>
				Na hora de fazer uma entrevista de competência é preciso ter em mente quais serão os pontos avaliados. Nem todos os cargos exigem certa habilidade comportamental ou o conhecimento técnico em um assunto.
			</p>

			<p>
				O ideal é verificar o que pede cada área e, principalmente, cada cargo. Um gerente de logística precisa de organização, comunicação e conhecimento quanto aos métodos de armazenamento e expedição. Já um analista financeiro deve ser pragmático e entender sobre balancetes e geração de diversos relatórios.
			</p>

			<p>
				Ao mesmo tempo, um cargo júnior não exige aprofundamento igual ao de um sênior. Então, tudo isso tem que ser considerado para definir o que fará com que uma pessoa seja contratada em detrimento das outras.
			</p>

			<b>A CONDUÇÃO DE UMA ENTREVISTA DE COMPETÊNCIA DE SUCESSO</b>

			<p>
				Para utilizar todo o potencial dessa ferramenta, a entrevista deve ser conduzida corretamente. Para que não restem dúvidas sobre o que fazer, veja alguns aspectos para considerar.
			</p>

			<b>ANALISE O CURRÍCULO COM ANTECEDÊNCIA</b>
			
			<p>
				A preparação é uma parte fundamental para esclarecer dúvidas a respeito de certas habilidades e para testar o que é dito no currículo. Portanto, o resumo profissional deve ser lido com antecedência, pois é o que vai direcionar a avaliação durante a entrevista.
			</p>

			<p>
				Veja se alguma experiência equivale ao cargo pretendido, confira se há uma habilidade que parece não se encaixar e identifique as competências não citadas que devem ser verificadas. Assim, o encontro é mais produtivo.
			</p>

			<b>MANTENHA O CONTROLE DA ENTREVISTA</b>

			<p>
				Para obter a objetividade, é importante que o entrevistador tenha o controle sobre o processo. A intenção é fazer o máximo possível de perguntas para entender quem é o candidato e o que ele pode oferecer.
			</p>

			<p>
				Ao agir dessa forma, você evita se perder ou prolongar demais um assunto que não está entre as prioridades de avaliação. Além disso, é um bom jeito de entender como o profissional reage em uma situação como essa.
			</p>

			<b>DEIXE ESPAÇO PARA O CANDIDATO FALAR</b>

			<p>
				Ao mesmo tempo, é importante deixar que o indivíduo fale, de modo a conhecê-lo melhor. Isso diminui as chances de ele ter respostas prontas ou que seguem um roteiro.
			</p>

			<p>
				Vale a pena fazer algumas perguntas abertas, como “O que você fez em determinada situação? Como se sentiu?”. Pergunte, também, como os colegas o descrevem, em vez de questioná-lo quanto a pontos fracos e fortes.
			</p>

			<p>
				Ao final, peça que ele fale, brevemente, sobre o que considera relevante e que não foi perguntado. Ao não criar perguntas fechadas, fica mais fácil ter um panorama do profissional.
			</p>

			<p>
				A entrevista de competência é objetiva e promete garantir total efetividade. Com a adoção correta, é simples encontrar o candidato certo para cada posição.
			</p>

			<p>
				Se quiser muitas outras dicas relevantes, assine a nossa newsletter e não perca nada!
			</p>
		</template>

		<template v-if="blog === 19" >

			<p>
				Os processos de recrutamento e seleção costumam ser lentos e caros. Para contratar um profissional realmente alinhado ao cargo e manter um bom índice de retenção, as empresas se utilizam de várias ferramentas e meios de avaliação. Uma delas é a avaliação por meio de testes psicológicos. Mas, como essas avaliações ajudam na seleção dos candidatos?
			</p>

			<p>
				Neste post, vamos explicar melhor o assunto, apresentar os principais tipos de testes e mostrar como eles colaboram para uma contratação mais eficiente. Se o assunto desperta o seu interesse, continue a leitura!
			</p>

			<b>O QUE SÃO TESTES PSICOLÓGICOS?</b>

			<p>
				Os testes psicológicos podem ser definidos como instrumentos para a mensuração ou avaliação de características psicológicas. Eles apontam tendências comportamentais e de personalidade, que ajudam a entender melhor o perfil do candidato.
			</p>

			<p>
				Dentro de um processo seletivo, a aplicação desses testes permite que o RH entenda se o profissional tem as soft skills necessárias para ocupar um cargo, aumentando, assim, o índice de assertividade no processo de R&S e retenção de funcionários.
			</p>

			<b>POR QUE AS AVALIAÇÕES PSICOLÓGICAS SÃO TÃO IMPORTANTES?</b>

			<p>
				As empresas começaram a perceber que para desenvolver um bom trabalho, o funcionário não deve ter apenas conhecimento técnico ou experiência. Ele deve se integrar à cultura da empresa, ter um bom relacionamento com os colegas e apresentar uma postura coerente com a função que vai exercer.
			</p>

			<p>
				Por exemplo, uma empresa que preza pela valorização dos seus funcionários e tem uma abordagem mais livre, certamente precisa de pessoas independentes e capazes de se autogerenciar. Por outro lado, organizações com uma cultura mais rígida e fortemente apoiada na hierarquia tendem a ter melhores resultados com funcionários que sabem seguir ordens.
			</p>

			<p>
				Para cargos de liderança, a avaliação psicológica também é muito importante. Afinal, por meio dela, é possível identificar se o profissional é do tipo autoritário, inspirador, engajador, controlador etc.
			</p>

			<p>
				Vale lembrar de que não há um perfil psicológico correto, há o mais adequado para a vaga, e principalmente, para a empresa. Cada organização deve ter consciência do tipo de colaborador que se adapta melhor às suas necessidades e consegue ter um bom desempenho naquele ambiente.
			</p>

			<b>QUAIS SÃO OS TIPOS DE TESTES PSICOLÓGICOS?</b>

			<p>
				Podemos agrupar os testes psicológicos usados para seleção de funcionários em três tipos.
			</p>

			<p>
				<b>1. TESTES DE PERSONALIDADE</b>

				<p>
					Esse tipo de avaliação exibe as principais características comportamentais do indivíduo, como qualidade de relacionamento interpessoal, estabilidade emocional, agressividade, proatividade, capacidade de trabalhar sob pressão, entre outros.
				</p>

				<p>
					A partir dos seus resultados, é possível prever tendências de comportamento e entender se o profissional vai se sair bem no cargo.
				</p>
			</p>

			<p>
				<b>2. RACIOCÍNIO LÓGICO</b>

				<p>
					Esse tipo de teste procura avaliar a capacidade de compreensão e de relacionar dados e informações em prol da solução de um problema. Nessas avaliações são medidas competências mentais, como lógica, raciocínio, aprendizagem etc.
				</p>
			</p>

			<p>
				<b>3. ATENÇÃO</b>

				<p>
					Avalia a capacidade do profissional de se manter focado em uma atividade, selecionando e permanecendo atento a estímulos-alvo, mesmo em ambientes sujeito a interferências.  A mensuração da atenção concentrada tem uma relação muito íntima com o rendimento de profissionais que atuam em funções de análise de dados e informações, por exemplo.
				</p>

				<p>
					Contar com uma consultoria especializada na aplicação de testes psicológicos é importante para ter um resultado mais acertado nas contratações e formar equipes mais coesas e produtivas, que consigam colaborar verdadeiramente com o desenvolvimento da empresa.
				</p>

				<p>
					Se você quer saber mais sobre os benefícios das avaliações psicológicas nos processos seletivos, confira também este material.
				</p>
			</p>

			<b>SAIBA COMO OTIMIZAR O PROCESSO DE RECRUTAMENTO E SELEÇÃO DA EMPRESA</b>

			<p>
				Ter uma equipe de qualidade dentro da empresa é fundamental para manter resultados elevados e uma imagem forte no mercado. Quando os profissionais demonstram desempenho satisfatório, isso reflete na organização de maneira bastante positiva: a rotatividade é praticamente controlada e os custos com saídas e contratações são reduzidos de maneira significativa.
			</p>

			<p>
				O segredo para que isso aconteça é investir na otimização do processo de recrutamento e seleção, já que um processo estagnado pode paralisar o progresso da sua empresa.
			</p>

			<p>
				Com as etapas bem definidas, e diante do entendimento pleno sobre qual é o perfil de colaborador necessário, fica muito mais simples montar um time que realmente represente a essência do negócio — e que possa aumentar a produtividade da organização.
			</p>

			<p>
				De fato, essa transformação é desafiadora. Mas alguns passos podem ajudar para que ela se torne muito melhor e mais ágil. Quer saber quais são eles? Então, leia este artigo com atenção e siga as dicas para contratar com maior segurança e assertividade.
			</p>

			<b>SAIBA EXATAMENTE QUE TIPO DEPROFISSIONAL É NECESSÁRIO</b>

			<p>
				Entender as diretrizes e especificações da vaga é muito importante para buscar o tipo de profissional correto. Se você sabe do que a empresa precisa, poderá investigar com mais precisão as características necessárias para a vaga e as competências indispensáveis para 
				preenchê-la.
			</p>

			<p>
				Outro fator a ser considerado é ter conhecimento acerca do próprio perfil da companhia e do que ela espera de seus colaboradores. Empresas que investem em dissecar as atribuições de um cargo, costumam ter um processo de recrutamento e seleção muito mais eficiente.
			</p>

			<p>
				Afinal, além de encontrar alguém que atenda em totalidade a demanda, a contratação se torna mais dinâmica e as atividades não entram em atraso e nem ficam acumuladas nas mãos de um funcionário que não está preparado para atendê-las.
			</p>

			<b>DIVULGUE CORRETAMENTE A VAGA OFERECIDA</b>

			<p>
				Muitas empresas demoram a encontrar o profissional adequado porque pecam na hora de divulgar a vaga oferecida. É interessante explicitar ao máximo tudo o que é procurado em um candidato, para evitar que pessoas que não se encaixam no perfil tomem tempo do setor de Recursos Humanos na etapa de triagem de currículos.
			</p>

			<p>
				Outra providência que ajuda a otimizar o processo de recrutamento e seleção nesse sentido é divulgar as vagas disponíveis no local certo. Sites e redes sociais costumam ter um alcance abrangente, mas não deixe de considerar outros meios que atinjam o público desejado.
			</p>

			<p>
				Explore plataformas, verifique como funcionam suas métricas e pesquise sobre a satisfação de outras empresas que já utilizaram o método.
			</p>

			<b>PADRONIZE O PROCESSO DE RECRUTAMENTO E SELEÇÃO</b>

			<p>
				Definir padrões e metodologias dentro do processo de recrutamento também contribui muito para sua otimização. Isso significa estabelecer as fases de seleção pelas quais os candidatos serão submetidos.
			</p>

			<p>
				Sem deixar de diferenciar essas etapas conforme o setor, visto que cada um deles apresenta uma exigência particular: alguns necessitarão até mesmo de testes práticos.
			</p>

			<p>
				Estude de maneira mais aprofundada sobre o cargo e alinhe melhor o processo com os gestores. Isso ajuda até mesmo os profissionais recrutadores a terem uma percepção mais detalhada sobre o funcionamento de cada área da empresa. Assim, caso seja necessário, podem sugerir mudanças.
			</p>

			<b>PROCURE POR TALENTOS DENTRO DA EMPRESA</b>

			<p>
				Promover a indicação de talentos internos é algo que ainda está ganhando espaço nas empresas. Muitas delas ainda não utilizam essa prática, mas trata-se de uma estratégia importante para a otimização expressiva no processo de recrutamento e ainda oferece uma chance de promoção ou mudança de setor para quem quer dar uma guinada na carreira sem sair da companhia.
			</p>

			<p>
				Disponibilizar essa opção garante mais comprometimento e dedicação dos colaboradores, reforça o conceito de fidelidade à empresa e ajuda a envolver ainda mais os funcionários.
			</p>

			<b>PROPONHA PERGUNTAS SIMPLES E RELEVANTES</b>

			<p>
				Muitas vezes os entrevistadores do setor de RH se concentram em questões interessantes, que acreditam que os ajudarão a entender tudo o que precisam saber sobre o candidato. Traçam questões hipotéticas que, às vezes, podem distrair o candidato — e até impedem que ele se exponha de verdade.
			</p>

			<p>
				É importante dizer que não há perguntas específicas que forneçam instantaneamente informações sobre o candidato. Em vez de investir em perguntas estranhas ou que possam “desconcertar” o entrevistado, aposte em indagações que permitam conhecer de verdade a experiência, as competências, a ética de trabalho e as condutas do candidato.
			</p>

			<p>
				Invista em perguntas realistas. Gaste seu tempo conhecendo melhor o candidato, explorando seus conhecimentos e adquirindo uma compreensão de sua experiência e personalidade.
			</p>

			<b>FAÇA USO DE TECNOLOGIA</b>

			<p>
				A tecnologia pode ser uma grande aliada do processo de recrutamento e seleção. Algumas etapas, como a triagem e o registro comportamental, correm ainda melhor com a ajuda de softwares e plataformas.
			</p>

			<p>
				Além disso, receber currículos e agendar entrevistas se tornam atividades mais dinâmicas quando realizadas online, por exemplo.
			</p>

			<p>
				Algumas ferramentas permitem que o conteúdo seja compartilhado com gestores e outros interessados no processo seletivo e ainda permitem que tudo fique registrado de maneira segura, por muito mais tempo.
			</p>

			<p>
				Em uma próxima oportunidade, é possível consultar as informações para resgatar candidatos que não foram selecionados em um primeiro momento para uma reavaliação, o que poupa bastante tempo também.
			</p>

			<p>
				Otimizar o processo de recrutamento e seleção é uma forma de assegurar a qualidade do trabalho da empresa e manter uma equipe fidelizada, satisfeita e totalmente preparada para lidar com os desafios do mercado.
			</p>

			<p>
				Contratar é uma ação que exige muito cuidado, mas que também permite que a organização conte com os melhores profissionais do mercado e consiga elevar seus resultados cada vez mais, sempre colocando-os em uma posição de valorização.
			</p>

			<p>
				Durante as etapas de recrutamento e seleção da empresa, é essencial ter um olhar analítico sobre os candidatos, respeitar a cultura organizacional da empresa e avaliar muito bem as competências técnicas e comportamentais necessárias.
			</p>

			<p>
				Esteja aberto para uma conversa franca com os interessados na vaga anunciada. Não se esqueça de que eles são seres humanos, que merecem empatia, atenção e compreensão.
			</p>

			<b>E QUANDO FICAMOS ENTRE ESCOLHER DOIS (OU ATÉ MAIS) CANDIDATOS “PERFEITOS” PARA O CARGO EM QUESTÃO?</b>

			<p>
				É muito comum em um processo de recrutamento e seleção que dois ou mais candidatos atendam aos requisitos da vaga, as competências técnicas e comportamentais requeridas. Neste caso, como escolher o candidato mais indicado? Uma ferramenta primordial para conseguirmos selecionar o perfil mais apropriado é a avaliação psicológica ou de competências.
			</p>

			<p>
				Utilizada para facilitar o trabalho do decisor, ela fornece um “mapa” dos traços de personalidade, imperceptíveis durante a entrevista. Com isso, consegue-se identificar as fragilidades e/ou potenciais de quem quer preencher a vaga, ou ainda identificar os padrões de comportamento deste indivíduo, como ele reagirá em determinada situação, etc.
			</p>

			<p>
				Importante ressaltar que existem diversos tipos de testes psicológicos no mercado. Para cada cargo/função é montada uma bateria específica, com os pontos mais importantes a serem avaliados.
			</p>

			<p>
				Na Sociis RH, trabalhamos com diversos testes que avaliam a personalidade, capacidade de atenção e raciocínio. Nossas psicólogas entendem as especificidades do cargo, para elencar quais são os testes mais indicados. Com isso conseguimos analisar todo o perfil do candidato e cruzamos com os requisitos da vaga, para verificarmos se ele está apto para estas atribuições.
			</p>

			<p>
				Quer saber como conseguimos aumentar a eficácia dos processos de recrutamento e seleção de nossos clientes? Fale com um de nossos consultores!
			</p>

			<p>
				E então, estas dicas foram úteis para você? Em caso positivo, não deixe de seguir o nosso perfil no Linkedin, além de curtir nossa página do Facebook, para se manter informado sobre outros assuntos e conteúdos tão interessantes quanto este!
			</p>
		</template>

		<template v-if="blog === 20" >

			<p>
				A decisão do momento ideal para contratar um novo funcionário (ou mais) pode ser um verdadeiro dilema para empresários, principalmente proprietários de pequenas empresas. Aumentar o quadro de colaboradores é um passo importante e exige um cauteloso planejamento prévio para não causar possíveis arrependimentos e custos que podem ser evitados.
			</p>

			<b>ATENÇÃO AOS SINAIS QUE A EMPRESA DÁ</b>

			<p>
				O primeiro passo é analisar a organização e os sinais que ela está dando de acordo com sua expansão. Funcionários sobrecarregados, fazendo horas extras frequentes e o atendimento ao cliente final prejudicado são os principais sinais que expõem a necessidade de nova(s) contratação(ões). Se as entregas estão sendo afetadas pelo acúmulo de tarefas dos integrantes da equipe, então nada mais natural do que aumentar o time antes que o desempenho da empresa seja prejudicado. Com uma divisão de tarefas mais consciente e eficaz, os clientes serão melhor atendidos e os prazos cumpridos.
			</p>

			<p>
				Lembre-se que o processo de selecionar, contratar e treinar um novo funcionário não acontece de uma hora pra outra, sendo realizado em etapas. Por isso, é sempre bom estar atento aos sinais que mencionamos anteriormente, para ter tempo hábil para que a nova pessoa chegue a tempo, antes que seja necessário “apagar incêndios”.
			</p>

			CHEGOU A HORA DE AUMENTAR O TIME

			<p>
				O primeiro passo para iniciar o processo de contratação é definir o perfil ideal do candidato. Quais os pré-requisitos a pessoa deve ter? Quais capacitações, conhecimentos específicos e experiências são desejáveis? Com essas informações determinadas, pode-se começar a divulgar a vaga, mencionando também a descrição detalhada das atividades que serão desempenhadas. Procure por plataformas de anúncios de vagas de trabalho e escolha as que estão mais alinhadas com o perfil e área profissional que você procura. A Sociis RH utiliza técnicas variadas para atrair e captar candidatos, utilizando tecnologias digitais atuais e aliadas a métodos tradicionais, mas também eficazes. Temos um banco de currículos atualizado e amplo, com profissionais das mais diversas áreas e segmentos de atuação.
			</p>

			<p>
				Durante o processo de recrutamento e seleção, deixe os pré-julgamentos e estereótipos de lado e avalie se os candidatos estão alinhados com os valores da sua empresa, pois essa sintonia é fundamental para o sucesso da parceria que pode ser criada. Após a contratação ser finalizada, é importante fazer um bom processo de integração para que o(s) novo(s) funcionário(s) seja(m) devidamente apresentado(s) à equipe e questões importantes devem ser passadas a ele(s) como os valores da empresa e suas metas estratégicas. Sabendo onde a empresa quer chegar e conhecendo as ferramentas disponíveis, fica mais fácil fazer a sua parte e contribuir para o todo.
			</p>
		</template>

		<template v-if="blog === 21" >

			<p>
				Uma das etapas mais importantes de um processo seletivo, a entrevista de emprego, é um desafio não apenas para quem está se candidatando a uma vaga, mas também para os entrevistadores. Conduzir a conversa de forma profissional e assertiva no que diz respeito a avaliação do candidato é fundamental, além do cuidado para que tanto entrevistador como entrevistado estejam confortáveis. Esse contato face a face é uma das etapas mais definidoras para identificar talentos, uma tarefa subjetiva e cheia de desafios.
			</p>

			<p>
				As pessoas responsáveis pela entrevista precisam se preparar para ela, entendendo detalhes da vaga em aberto, as responsabilidades inerentes ao cargo e as competências mais valorizadas para o candidato que irá ocupá-lo. Também é de bom tom se informar previamente sobre a formação e experiências profissionais anteriores do entrevistado, o que facilita a elaboração de perguntas e permite uma visualização mais ampla do contexto profissional da pessoa, sendo uma importante referência para orientar a entrevista.
			</p>

			<p>
				Informar detalhes da vaga com clareza e mostrar disponibilidade e interesse naquela conversa são as melhores formas de começar uma boa entrevista. Olhar no olho, ser cortês e profissional. Evitar questionamentos indelicados e invasivos é importante para o sucesso da entrevista, que deve ser focada nas competências necessárias para a posição de trabalho em questão. A seguir, algumas dicas de questões que devem ser evitadas e que nada agregam nessa fase de um processo de recrutamento:
				<ul>
					<li>– Evitar perguntas sobre a vida pessoal do entrevistado como religião, orientação sexual, posicionamento político, relacionamento com a família e amorosos.</li>
					<li>– Idade também é um item para evitar questionar, a não ser que o candidato aparente ser menor de idade.</li>
					<li>– Não questionar detalhes da vida financeira do entrevistado, como acúmulo de bens.</li>
					<li>– Para mulheres, não fazer perguntas que podem ser consideradas discriminação, como se está grávida ou se pretende engravidar.</li>
					<li>– Para candidatos mais velhos, não perguntar quando pretendem se aposentar.</li>
					<li>– Não questionar sobre condições médicas e, caso o cargo exija certas habilidades físicas, fazer perguntas específicas sobre o atendimento àquelas atividades, mas sem perguntar diretamente sobre possíveis limitações.</li>
				</ul>
			</p>

			<p>
				Outra dica é fazer perguntas mais precisas, e não muito abrangentes. Considere sempre que o candidato provavelmente estará nervoso durante a entrevista, portanto, ser direto e claro nas perguntas beneficia o ritmo da conversa. O segredo para boas perguntas durante um processo seletivo é a preparação e estruturação do processo, de forma que o entrevistador esteja ciente dos objetivos desta etapa e das competências e habilidades desejadas. Também é importante se colocar à disposição do candidato para possíveis dúvidas, estimulando que ele também faça perguntas, o que sempre é uma demonstração de interesse.
			</p>
		</template>

		<template v-if="blog === 22" >

			<p>
				Entender o perfil de colaborador mais adequado para atuar nas empresas é o passo mais importante para contratações assertivas. Antes da equipe de gestão de Recursos Humanos planejar as etapas e detalhes do processo seletivo, um questionamento importante deve ser feito: qual o tipo de profissionais que gostaríamos de atrair?
			</p>

			<p>
				<b>Sintonia entre colaborador e organização</b>
				<br><br>

				A dica número 1 é confirmar que a empresa já tenha sua missão, visão e valores estabelecidos de forma sólida, dessa forma é possível buscar candidatos com objetivos parecidos que tenham a contribuir para seu alcance. Ao compartilhar com os valores da organização e com a imagem que ela quer passar ao seu público, o colaborador tem muito mais capacidade de gerar valor para aquela empresa. Essa sintonia é fundamental, já que o alinhamento dos objetivos da empresa e do profissional tornará mais fácil encontrar o tipo de colaborador que melhor poderá se encaixar no novo cargo: queremos alguém mais ousado e inovador ou mais conservador? Mais impulsivo ou mais analítico? Mais preparados para o trabalho em equipe ou que preferem tarefas individuais? Com habilidades de liderança, ou isso não é tão importante?
			</p>

			<p>
				Depois de fazer essa comparação, é válido enumerar as competências comportamentais e habilidades gerais esperadas para aquele determinado cargo, o que também pode ser avaliado por meio das formações acadêmicas e experiências profissionais anteriores dos candidatos. Entretanto, é importante lembrar que muitas dessas habilidades podem ser questões mais subjetivas e portanto a ausência delas não necessariamente deve ser critério de eliminação. É mais importante focar no perfil comportamental do candidato, tendo em mente que o conhecimento mais técnico da função sempre pode ser desenvolvido e melhor explorado.
			</p>

			<p>
				<b>As velhas e boas atitudes mais valorizadas</b>
				<br><br>

				De forma geral, a maioria das empresas busca pessoas que desejam gerar resultados, o que está relacionado a características como dedicação, comprometimento, proatividade, criatividade, energia para encarar desafios e interesse em aprender e crescer profissionalmente; a valorizada VISÃO. Essas atitudes facilitam a colocação de qualquer pessoa no mercado de trabalho e sempre é bem-vinda nas empresas, por isso devem ser sempre avaliadas nos processos seletivos, o que pode ser feito por meio de entrevistas e testes, indo muito além da análise de currículos. O mercado está cheio de pessoas com experiências e características muito variadas e focar apenas na formação técnica dos candidatos pode ser superficial para a maioria dos cargos disponíveis. Lembre-se que contratações inteligentes são a base para a formação de equipes de alta performance!
			</p>
		</template>

		<template v-if="blog === 23" >

			<p>
				As várias etapas que fazem parte de um processo seletivo devem ser encaradas com seriedade por todos os envolvidos, já que possui vários desafios não apenas para quem estará participando dele, mas também para os profissionais que o planejam e executam do lado “de dentro” da empresa. A base para um recrutamento de qualidade está na elaboração de boas perguntas e na escolha de métodos eficientes para encontrar os candidatos mais adequados para a(s) vaga(s) de trabalho disponíveis.
			</p>

			<p>
				Mensurar o perfil dos candidatos vai muito além de obter mais detalhes sobre suas formações acadêmicas e experiências profissionais anteriores e também envolve conhecer um pouco sobre suas características pessoais que podem influenciar sua atuação no trabalho. E é aí que identifica-se um grande desafio. Quais informações devem ser questionadas neste sentido? O que é e o que não é apropriado de ser perguntado pelo(s) recrutador(es)? Qual o limite do nível de perguntas pessoais que devem ser feitas?
			</p>

			<p>
				A dica mais importante é manter o bom senso e evitar questionamentos delicados que podem deixar o candidato desconfortável e não acrescentam em nada à entrevista de cunho profissional. Se a questão não interfere no desempenho da pessoa que estará ocupando aquela posição de trabalho, a regra é: não pergunte. Alguns exemplos são: se o candidato pretende ou não se casar ou ter filhos, questionamentos religiosos e sobre crenças espirituais de qualquer tipo, perguntar sobre sua orientação sexual, informações sobre condições de saúde (com exceção de cargos que exigem determinadas habilidades físicas), e solicitação de detalhes sobre sua vida social.
			</p>

			<p>
				A performance de um colaborador na empresa não está relacionada a como ele passa seu tempo livre, desde que isso não influencie em suas habilidades físicas e psíquicas, sendo assim é fundamental focar no que realmente importa como habilidades, capacitações, aspirações profissionais, conhecimentos, e tudo o que é relevante de verdade para a contratação de um novo membro para o time: suas competências profissionais. Claro que todo profissional é um ser humano com características pessoais que querendo ou não podem afetar seu desempenho no trabalho, mas cabe aos recrutadores terem o máximo de cuidado para não violarem a privacidade dos candidatos para não atingirem a intimidade da pessoa de forma embaraçosa durante processos seletivos.  Repeito e bom senso nunca são demais ao lidar com pessoas e é sempre bom reforçar isso na esfera profissional.
			</p>
		</template>

	</div>
</template>

<script>
	export default {
		name: "BlogContentListComponent",
		props: ['blog']
	}
</script>